<template>
  <div
    style="position: relative;"
  >
    <template v-if="count !== null">
      <div style="height: 24px; width: 100%; position: absolute;">
        <span
          style="position: absolute; right: 12px; font-size: 1.125rem; font-weight: bold;"
          class="zero--text"
          :class="{'non--zero--text': count > 0}"
        >{{ count }}</span>
      </div>
    </template>
    <template v-if="!!vIcon">
      <v-icon>mdi-calendar-clock</v-icon>
    </template>
    <template v-else>
      <v-img
        :src="icon"
        class="d-block mx-auto"
        :class="{'zero--image': count === 0}"
        style="top: 12px;"
        max-height="26px"
        max-width="24px"
      />
    </template>
    <div
      style="position: relative; top: 20px; font-size: 0.813rem;"
      class="d-block mx-auto"
      :class="{'zero--text': count === 0}"
    >
      {{ title }}
    </div>
  </div>
</template>

<script>
    export default {
        name: "AsCounter",
        props: {
            title: {type: String, default: 'unknown'},
            count: {type: Number, default: 0},
            icon: {type: String, default: undefined},
            vIcon: {type: String, default: undefined},
        }
    }
</script>

<style scoped>
  .zero--text {
    color: #ccc;
  }
  .non--zero--text {
    color: #0C98FE;
  }
  .zero--image {
    opacity: 0.3;
  }
</style>