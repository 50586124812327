<script>
    import {Bar} from "vue-chartjs";
    import ChartVerticalBarPlugin from "./chart-plugin/ChartVerticalBarPlugin";

    export default {
        name: "ChartVerticalBar",
        extends: Bar,
        mixins: [ChartVerticalBarPlugin],
        props: {
            chartData: {
                type: Object,
                default: null
            },
            isTooltip: {
                type: Boolean,
                default: true,
            },
            isLegend: {
                type: Boolean,
                default: true,
            },
            isPlugin: {
                type: Boolean,
                default: true,
            },
            /**
             * isPlugin 값이 true 일 때, 해당 변수에 chartDoughnutPlug 에 등록한 함수와
             * 매칭되는 plugin id 값을 넣어주세요.
             */
            requiredPluginIds: {
                type: Array,
                default: () => ([])
            },
            barLabelCssObject: {
                type: null,
                default: null
            },
            barCssObject: {
                type: Object,
                default: null,
            },
            barScales: {
                type: Object,
                default: null,
            },
            stepSize: {
                type: Number,
                default: 0,
            },
        },
        /**
         *  각 페이지에 사용할 플러그인을 등록
         *      1. chartVerticalBarPlugin.vue 에 함수 등록
         *      2. 현재 페이지의 setPlugin 에서 id 값 받아서 처리 할 수 있도록 등록
         */
        data() {
            return {
                options: {
                    responsive: true,
                    tooltips: {
                        enabled: this.isTooltip,
                        callbacks: {
                            label: function(tooltipItem, data) {
                                return Number(tooltipItem.value).toLocaleString();
                            }
                        }
                    },
                    legend: {
                        display: this.isLegend,
                        position: 'top',
                    },
                    showValue: {
                        fontStyle: 'Helvetica',
                        fontSize: 20
                    },
                    scales: this.barScales,
                    plugins: {},
                    ...this.barCssObject,
                }
            }
        },
        watch: {
            chartData() {
                this.render();
            }
        },
        mounted() {
            this.setPlugin();
            this.render();
        },

        methods: {
            setStepSize(){
                this.options.scales.yAxes[0].ticks.stepSize = this.stepSize
            },
            render() {
                if(this.stepSize){
                    this.setStepSize();
                }

                this.renderChart(this.chartData, this.options);
            },
            getBase64Image() {
                return this.$data._chart.toBase64Image();
            },
            setPlugin() {
                if (this.isPlugin && this.requiredPluginIds.length > 0) {
                    this.requiredPluginIds.map(id => {
                        switch (id) {
                            case 'itemCategoryName':
                                this.addPlugin(
                                    {
                                        id: 'itemCategoryName',
                                        afterDraw: this.itemCategoryName
                                    })
                                break;
                            case 'yAxisUnit':
                                this.addPlugin(
                                    {
                                        id: 'yAxisUnit',
                                        afterDraw: this.yAxisUnit
                                    })
                                break;
                            case 'legendMarginBottom':
                                this.addPlugin(
                                    {
                                        id: 'legendMarginBottom',
                                        beforeInit: this.legendMarginBottom
                                    })
                                break;
                        }
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>