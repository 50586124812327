<template>
  <div
    v-show="loading"
    class="loading_wrap"
  >
    <div class="loading" />
    <div class="loading" />
    <div class="loading" />
  </div>
</template>

<script>
    export default {
        name: "DotLoadingBar",
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<style scoped>
    /* 로딩 아이콘 시작 */
    .loading_wrap {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }

    .loading {
        width: 8px;
        height: 8px;
        background: #aaaaaa;
        border-radius: 50%;
        animation: loading-cricle 1.5s infinite ease-in-out;
        display: inline-block;
        margin-right: 8px;
    }

    .loading:nth-child(2) {
        animation-delay: .5s;
    }

    .loading:nth-child(3) {
        animation-delay: 1s;
    }

    @keyframes loading-cricle {
        0% {
            opacity: .4;
            transform: scale(1, 1);
        }
        50% {
            opacity: 1;
            transform: scale(1.2, 1.2);
        }
        100% {
            opacity: .4;
            transform: scale(1, 1);
        }
    }
</style>
