<template>
  <v-card
    v-ripple="false"
    class="mx-auto"
    width="100%"
    style="position: relative;"
    outlined
    @click.stop.prevent="onWorkClicked"
  >
    <div
      v-if="!isValid"
      style="position: absolute; background: rgba(244, 244, 244, 0.5); width: 100%; height: 100%; pointer-events: none; z-index: 3;"
      @click.stop.prevent
    />
    <v-list-item
      v-ripple="false"
      class="pa-0 boder-bottom"
    >
      <v-list-item-content class="pa-0 pt-3">
        <v-list-item-subtitle class="mt-0">
          <div
            class="caption text-left black--text font-weight-bold"
            style="font-size: 1rem !important;"
          >
            {{ item.staffFullname }}
          </div>

          <v-row
            justify="space-between"
            align="end"
            class="mx-0 flex-wrap"
          >
            <div class="staff__WorkArea">
              <div
                class="d-flex"
                style="padding-bottom: 12px"
              >
                <div
                  class="zero--text work__Status__Area"
                  :class="{'wait--text': item.asWaitCnt > 0}"
                >
                  {{ '대기' | translate }} : {{ item.asWaitCnt || 0 }}
                </div>

                <div
                  class="zero--text work__Status__Area"
                  :class="{'proc--text': item.asProcCnt > 0}"
                >
                  {{ '진행' | translate }} : {{ item.asProcCnt || 0 }}
                </div>

                <div
                  class="zero--text work__Status__Area"
                  :class="{'green--text': item.asDayFinishCnt > 0}"
                >
                  {{ '당일완료' | translate }} : {{ item.asDayFinishCnt || 0 }}
                </div>

                <div
                  class="zero--text work__Status__Area"
                  :class="{'red--text': item.asDayCancelCnt > 0}"
                >
                  {{ '당일취소' | translate }} : {{ item.asDayCancelCnt || 0 }}
                </div>
              </div>

              <div
                style="position: relative; bottom: 8px;"
                class="d-flex justify-space-between"
              >
                <context-icon-menu :tel-no="item.userMobileno" />
              </div>
            </div>
          </v-row>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
    import AsMainWorkorderSubList from "@/components/windows/as/AsMainWorkorderSubList";
    import VuexProps from "@/framework/vuex/VuexProps";
    import ContextIconMenu from "@/components/widgets/ContextIconMenu";

    export default {
        name: "WorkStatusItem",
        components: {ContextIconMenu},
        mixins: [VuexProps],
        props: {
            item: {type: Object, default: undefined}
        },
        data() {
            return {
                colorList: ["indigo", "teal", "orange", "blue", "cyan", "green", "lime", "yellow", "amber", "deep-orange"],
            }
        },
        computed: {
            allCnt() {
                return this.item.allCnt;
            },
            /**
             * 현재 할당된 도는 당일 취소, 완료된 작업이 하나라도 있는지 체크합니다.
             */
            isValid() {
                return this.allCnt > 0
            },
        },
        methods: {
            avatarColor(staffFullname) {
                if (!staffFullname) return "grey";
                const firstChar = staffFullname.charCodeAt(0);
                return this.colorList[isNaN(firstChar) ? 'grey' : firstChar % this.colorList.length];
            },

            /**
             * 작업 아이템이 선택되면 호출됩니다.
             * @param work 작업 아이템 in this.items
             */
            onWorkClicked() {
                if (!this.isValid) return;
                this.vuexManager.saveProps("SubListMain", {
                    titlePassed: this.item.staffFullname,
                })
                this.routerManager.push({name: "AsMainWorkorderSubList", params: {staffId: this.item.staffId}});
            },
        }
    }
</script>

<style scoped>
    .zero--text {
        color: #ccc;
    }

    .wait--text {
        color: #000;
    }

    .proc--text {
        color: #000;
    }

    >>> .v-list-item {
        min-height: 80px;
        padding: 0 8px;
    }

    .asstaffArea {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 45px;
        flex: auto;
    }

    .staff__WorkArea {
        display: flex;
        justify-content: space-between;
        flex: auto;
        flex-wrap: wrap;
        align-content: space-between;
    }

    .work__Status__Area {
        text-align: left;
        font-size: .813rem;
        margin-right: 8px;
        line-height: 25px;
    }

    .lineHeight-30 {
        line-height: 30px;
    }

    .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
        border-radius: 0 !important;
    }

    .theme--light.v-card.v-card--outlined {
        border: none !important;
    }

    .color__1 {
        color: #EC4DA3;
    }

    .color__2 {
        color: #0C98FE;
    }

    .color__3 {
        color: #000;
    }

    .color__4 {
        color: #FF0000;
    }

    .theme--light.v-sheet--outlined {
        border: none !important;
    }

    >>> .theme--light.v-sheet--outlined {
        border: none !important;
    }

    /*  @media (min-width: 768px) {
        .asstaffArea {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: 100px;
          max-width: 150px;
          flex: auto;
        }

        .staffFullname {
          overflow:hidden;
          text-overflow:ellipsis;
          white-space:pre;
          max-width: 100px;
        }
      }*/
</style>