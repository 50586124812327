<template>
  <div>
    <div>
      <div
        v-if="items.child[0]"
        class="total_count_wrap"
      >
        <div class="flex justify-space-between">
          <div class="font-weight-bold">
            {{ '총 건수' | translate }}
          </div>
          <div class="font-weight-bold">
            {{ workForSum && workForSum.totalAsCount | currency }} {{ '건' | translate }}
          </div>
        </div>
        <div class="flex justify-space-between">
          <div>{{ 'A/S 처리 합계' | translate }}</div>
          <div>{{ workForSum && workForSum.completionAsCount | currency }} {{ '건' | translate }}</div>
        </div>
        <div class="flex justify-space-between">
          <div>{{ 'A/S 미처리 합계' | translate }}</div>
          <div>{{ workForSum && workForSum.progressAsCount | currency }} {{ '건' | translate }}</div>
        </div>
      </div>
      <div v-if="items.child[1]">
        <dashboard-widget-table
          :header="tableHeader"
          :items="tableItems"
        />
      </div>

      <renewal-dashboard-textarea-component
        v-show="items.child[2]"
        :comment-info="comment"
        :date="date"
        name="as"
      />
    </div>
  </div>
</template>

<script>
    import {mapGetters, KEYS} from "@/store/modules/dashboard";
    import DashboardWidgetTable from "../dashboard-widget/DashboardWidgetTable";
    import RenewalDashboardTextareaComponent
      from "@/views/home/dashboard/dashboard-widget/RenewalDashboardTextareaComponent";


    export default {
        name: "DashboardWidgetAsWindow",
        components: {
          RenewalDashboardTextareaComponent,
            DashboardWidgetTable,
        },
        props: {
            items: Object,
            duration: Number,
            date: {
              type: String,
              default: '2021-10'
          }
        },
        data() {
            return {
                tableHeader: [this.$translate('담당자'), this.$translate('배정건수'), this.$translate('처리건수'), this.$translate('당일처리건수')],
            }
        },
        computed: {
            ...mapGetters({
                workForSum: KEYS.GET_WORK_SUM_DATA,
                workForTrend: KEYS.GET_WORK_LIST_DATA,
            }),
            tableItems() {
                return this.workForTrend?.data || []
            },
          comment(){
            return this.workForSum?.comment || {};
          },
        },
        created() {
        },
        methods: {
            setLocaleString(num) {
                return num ? Number(num).toLocaleString() : '0'
            }
        }
    }
</script>

<style scoped>

    .total_count_wrap {
        width: 100%;
        background-color: #f8f8f8;
        display: flex;
        flex-direction: column;
        padding: 10px;
        border-radius: 4px;
        font-size: 0.875rem;
        justify-content: space-between;
        font-weight: 500;
        margin-bottom: 5%;
        margin-top: 3%;
    }

</style>