<template>
  <as-main-workorder-list
    ref="asMainWorkorderList"
    :staff-id="staffId"
    no-bottom
    add-button
  />
</template>

<script>
    import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
    import AsMainWorkorderList from "@/components/windows/as/AsMainWorkorderLIst";

    export default {
        name: "AsMainWorkorderSubList",
        components: {AsMainWorkorderList},
        extends: WindowComponent,
        props: {
            staffId: undefined,
        },
        data() {
            return {}
        },
    }
</script>
