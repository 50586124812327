<template>
  <div class="dashboard_table_wrap">
    <table
      cellspacing="0"
      cellpadding="0"
    >
      <tr>
        <th
          v-for="(h,i) in header"
          :key="`h-${i}`"
        >
          {{ h }}
        </th>
      </tr>
      <tr
        v-for="(item,j) in items"
        :key="`c-${j}`"
      >
        <td class="text-left">
          {{ (item && item.printerModelName) || '-' }}
        </td>
        <td v-if="showInfo.child[0]">
          {{ item && item.totalPrinterCount | currency }}
        </td>
        <td v-if="showInfo.child[0]">
          {{ item && item.newPrinterCount | currency }}
        </td>
        <td v-if="showInfo.child[0]">
          {{ item && item.cancelPrinterCount | currency }}
        </td>
        <td
          v-if="showInfo.child[1] && salesConfigPermission"
          class="total_price"
        >
          {{ item && item.totalContractFee | currency }}
        </td>
        <td v-else>
          HIDDEN
        </td>
      </tr>
    </table>

    <!--항목이 10개 이상일때 더보기 버튼을 눌러 리스트를 뿌려주세요-->
    <div
      v-if="!isShowAll"
      class="more_view_button"
      @click="onChangeIsShowAll"
    >
      <div>{{ '더보기' | translate }}</div>
      <v-icon>mdi-chevron-down</v-icon>
    </div>

    <div
      v-else
      class="close_view_button"
      @click="onChangeIsShowAll"
    >
      <div>{{ '접기' | translate }}</div>
      <v-icon>mdi-chevron-up</v-icon>
    </div>
  </div>
</template>

<script>
    import userManager from "@/assets/plugins/mps-common/user/user-manager";
    import Permissions from "@/constants/permissions";

    export default {
      name: "DashboardWidgetContractTable",
      props: {
            showInfo: Object,
            items: Array,
            header: Array,
            isShowAll: Boolean
        },
      computed: {
        salesConfigPermission() {
          return userManager.hasPermission(Permissions.EXPOSE_ACCOUNT_INFO_SALES_CONFIG)
        }
      },
        methods: {
            setLocaleString(num) {
                return num ? Number(num).toLocaleString() : '0'
            },
            onChangeIsShowAll(){
                this.$emit('onChangeIsShowAll')
            }
        },
    }
</script>

<style scoped>
    .dashboard_table_wrap {
        height: auto;
    }

    table {
        width: 100%;
        text-align: center;
        table-layout: fixed;
    }

    table tr th {
        color: #888;
        font-weight: normal;
        font-size: 0.833rem;
        border-bottom: 1px solid #ddd;
        padding-bottom: 4px;
        /* background-color: white;
         position: sticky;
         top: 0;*/
    }

    table tr th:first-child {
        width: 35%;
    }

    table tr th:nth-child(2),
    table tr th:nth-child(3),
    table tr th:nth-child(4) {
        width: 13%;
    }

    table tr td {
        color: #333;
        font-weight: 500;
        font-size: 0.833rem;
        border-bottom: 1px solid #ddd;
        padding: 4px 2px;
        text-align: right;
    }

    .more_view_button {
        display: flex;
        justify-content: center;
        font-size: 0.875rem;
        margin-top: 12px;
    }

    .close_view_button {
        display: flex;
        justify-content: center;
        font-size: 0.875rem;
        margin-top: 12px;
    }

   .total_price {
     padding: 4px 8px 4px 4px;
   }

    * {
        font-family: "Noto Sans KR", sans-serif;
    }
</style>