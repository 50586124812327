<template>
  <div class="memo_wrap">
    <div class="title_wrap">
      <div class="chart_subtitle">
        {{ '담당자 의견' | translate }}
        ({{ '500자 이내' | translate }})
      </div>
      <div
        class="add_button_wrap"
        @click="save"
      >
        <img src="@/assets/images/dashboard/svg_check.svg">
        <div>{{ '저장' | translate }}</div>
      </div>
    </div>
    <div>
      <textarea
        v-model="text"
        rows="3"
        class="overflow-y-auto"
      />
    </div>
  </div>
</template>

<script>
    import {mapActions, KEYS} from "@/store/modules/dashboard";

    export default {
        name: "RenewalDashboardTextareaComponent",
        props: {
            commentInfo: {
              type: Object,
              default: () => ({})
            },
            date: {
              type: String,
              default: '2021-10'
            },
            name: {
              type: String,
              default: ''
            }
        },
        data() {
            return {
                text: '',
            }
        },
        watch: {
            commentInfo : {
                deep: true,
                handler(){
                    this.text = this.commentInfo?.comment
                }
            }
        },
        methods: {
            ...mapActions({
                updateComment: KEYS.UPDATE_COMMENT,
            }),
            showToast(result){
                result
                    ? this.$snackbarSuccess(this.$translate("저장이 완료 되었습니다."))
                    : this.$snackbarError(this.$translate('저장이 실패하였습니다.'));
            },
            save(){
                this.updateComment({
                    data: [{
                        ...this.commentInfo,
                        regMonth: this.$parseDate(this.date),
                        comment: this.text,
                    }],
                    cb: this.showToast
                })
            }
        },
    }
</script>

<style scoped>
    .memo_wrap {
        margin-top: 21px;
    }

    .title_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .chart_subtitle {
        font-size: 0.917rem;
        font-weight: 500;
        margin-bottom: 8px;
    }

    .add_button_wrap {
        display: flex;
        cursor: pointer;
        align-items: center;
    }

    .add_button_wrap > div {
        font-size: 0.875rem;
    }

    textarea {
        border: 1px solid #ddd;
        width: 100%;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
    }
</style>