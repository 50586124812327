<template>
  <div>
    <div v-if="salesPermission">
      <div
        v-if="items.child[0]"
        class="total_count_wrap"
      >
        <div class="total_count_subtitle">
          {{ '매출' | translate }}
        </div>
        <div class="total_count">
          <div class="total_count_sum">
            <div>{{ '총'|translate }}</div>
            <div class="automation_count">
              <div>
                {{ sales.totalEstimatedCount | currency }}
                {{ '건' | translate }}
              </div>
              <div>|</div>
              <div>
                {{ sales.totalEstimatedPrice | currency }}
                {{ '원' | translate }}
              </div>
            </div>
          </div>
          <div>
            <div class="total_count_item">
              <div>{{ '등록'|translate }}</div>
              <div class="automation_count">
                <div>
                  {{ sales.registeredCount | currency }}
                  {{ '건' |translate }}
                </div>
                <div>|</div>
                <div>
                  {{ sales.registeredPrice | currency }}
                  {{ '원' |translate }}
                </div>
              </div>
            </div>
            <div class="total_count_item">
              <div>{{ '예정'|translate }}</div>
              <div class="automation_count">
                <div>
                  {{ sales.estimatedCount | currency }}
                  {{ '건' |translate }}
                </div>
                <div>|</div>
                <div>
                  {{ sales.estimatedPrice | currency }}
                  {{ '원' |translate }}
                </div>
              </div>
            </div>
            <div class="total_count_item">
              <div>{{ '실패'|translate }}</div>
              <div class="automation_count">
                <div>
                  {{ sales.failedCount | currency }}
                  {{ '건'|translate }}
                </div>
                <div>|</div>
                <div>
                  {{ sales.failedPrice | currency }}
                  {{ '원'|translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="$userManager.countryFeature('KR') && items.child[1]"
        class="total_count_wrap"
      >
        <div class="total_count_subtitle">
          CMS
        </div>
        <div class="total_count">
          <div class="total_count_sum">
            <div>{{ '총'|translate }}</div>
            <div class="automation_count">
              <div>
                {{ cms.totalCallCnt | currency }}
                {{ '건' | translate }}
              </div>
              <div>|</div>
              <div>
                {{ cms.totalCallAmount | currency }}
                {{ '원' | translate }}
              </div>
            </div>
          </div>
          <div>
            <div class="total_count_item">
              <div>{{ '완료'|translate }}</div>
              <div class="automation_count">
                <div>
                  {{ cms.totalSuccessCnt | currency }}
                  {{ '건'|translate }}
                </div>
                <div>|</div>
                <div>
                  {{ cms.totalSuccessAmount | currency }}
                  {{ '원' |translate }}
                </div>
              </div>
            </div>
            <div class="total_count_item">
              <div>{{ '처리중'|translate }}</div>
              <div class="automation_count">
                <div>
                  {{ cms.totalReadyCnt | currency }}
                  {{ '건' |translate }}
                </div>
                <div>|</div>
                <div>
                  {{ cms.totalReadyAmount | currency }}
                  {{ '원'|translate }}
                </div>
              </div>
            </div>
            <div class="total_count_item">
              <div>{{ '실패'|translate }}</div>
              <div class="automation_count">
                <div>
                  {{ cms.totalFailCn | currency }}
                  {{ '건' |translate }}
                </div>
                <div>|</div>
                <div>
                  {{ cms.totalFailAmount | currency }}
                  {{ '원'|translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="$userManager.countryFeature('KR') && items.child[2]"
        class="total_count_wrap"
      >
        <div class="total_count_subtitle">
          {{ '세금계산서' | translate }}
        </div>
        <div class="total_count">
          <div class="total_count_sum">
            <div>{{ '총'|translate }}</div>
            <div class="automation_count">
              <div>
                {{ tax.totalCount | currency }}
                {{ '건' | translate }}
              </div>
              <div>|</div>
              <div>
                {{ tax.totalAmount | currency }}
                {{ '원' | translate }}
              </div>
            </div>
          </div>
          <div>
            <div class="total_count_item">
              <div>{{ '완료'|translate }}</div>
              <div class="automation_count">
                <div>
                  {{ tax.plusCount | currency }}
                  {{ '건' | translate }}
                </div>
                <div>|</div>
                <div>
                  {{ tax.plusAmount | currency }}
                  {{ '원' | translate }}
                </div>
              </div>
            </div>
            <div class="total_count_item">
              <div>{{ '취소'|translate }}</div>
              <div class="automation_count">
                <div>
                  {{ tax.cancelCount | currency }}
                  {{ '건'| translate }}
                </div>
                <div>|</div>
                <div>
                  {{ tax.cancelAmount | currency }}
                  {{ '원'| translate }}
                </div>
              </div>
            </div>
            <div class="total_count_item">
              <div>{{ '처리중'|translate }}</div>
              <div class="automation_count">
                <div>
                  {{ tax.sendingCount | currency }}
                  {{ '건'| translate }}
                </div>
                <div>|</div>
                <div>
                  {{ tax.sendingAmount | currency }}
                  {{ '원' | translate }}
                </div>
              </div>
            </div>
            <div class="total_count_item">
              <div>{{ '실패'|translate }}</div>
              <div class="automation_count">
                <div>
                  {{ tax.failCount | currency }}
                  {{ '건' | translate }}
                </div>
                <div>|</div>
                <div>
                  {{ tax.failAmount | currency }}
                  {{ '원' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <renewal-dashboard-textarea-component
        v-show="items.child[3]"
        :comment-info="comment"
        :date="date"
        name="auto"
      />
    </div>
    <div v-else>
      <no-items
        :title="'매출 조회 권한 없음.' | translate"
      />
      <div />
    </div>
  </div>
</template>

<script>

    import {mapGetters, KEYS} from "@/store/modules/dashboard";
    import RenewalDashboardTextareaComponent
        from "@/views/home/dashboard/dashboard-widget/RenewalDashboardTextareaComponent";
    import userManager from "@/assets/plugins/mps-common/user/user-manager";
    import Permissions from "@/constants/permissions";
    import NoItems from "@/components/items/NoItems.vue";

    export default {
        name: "DashboardWidgetAutomationWindow",
        components: {NoItems, RenewalDashboardTextareaComponent},
        props: {
            items: {
                type: Object,
                default: () => ({child: [true, true, true]})
            },
            duration: {
                type: null,
                default: null
            },
            date: {
                type: String,
                default: '2021-10'
            }
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters({
                autoForSum: KEYS.GET_AUTO_SUM_DATA,
            }),
            sales(){
                return this.autoForSum?.sales || {}
            },
            cms(){
                return this.autoForSum?.cms || {}
            },
            tax(){
                return this.autoForSum?.tax || {}
            },
            comment(){
                return this.autoForSum?.comment || {};
            },
          salesPermission() {
            return userManager.hasPermission(Permissions.EXPOSE_BILLING_MANAGE)
          },
        },
    }
</script>

<style scoped>
    .chart_subtitle {
        font-size: 0.917rem;
        font-weight: 500;
        margin: 3% 0 2%;
    }

    .total_count_wrap {
        width: 100%;
        background-color: #f8f8f8;
        align-items: center;
        padding: 10px;
        border-radius: 4px;
        font-size: 0.875rem;
        justify-content: space-between;
        font-weight: 500;
        margin-top: 3%;
    }

    .total_count_subtitle {
        font-size: 0.875rem;
        margin-bottom: 10px;
        font-weight: bold;
        text-align: left;
    }

    .total_count_sum {
        display: flex;
        justify-content: space-between;
    }

    .total_count_sum div {
        margin-bottom: 2px;
    }

    .total_count_item {
        display: flex;
        justify-content: space-between;
    }

    .total_count_item div {
        margin-bottom: 2px;
    }

    .automation_count {
        display: flex;
        width: 64%;
    }

    .automation_count div:nth-child(1) {
        width: 33%;
        text-align: right;
    }

    .automation_count div:nth-child(2) {
        width: 13%;
        color: #aaa;
    }

    .automation_count div:nth-child(3) {
        width: 60%;
        text-align: right;
    }

    /*.automation_count div:first-child:after {
        content: url("/img/dashboard/vertical_line.svg");
        margin: 0 6px;
        color: #B2B2B2;
    }*/
</style>