<template>
  <div style="padding: 8px 16px;">
    <v-btn-toggle
      v-model="cycleModel"
      mandatory
      group
      style="width: 100%; margin-bottom: 12px;"
    >
      <v-btn
        value="daily"
        class="classification"
        :color="cycleModel === 'daily' ? '#2481C4' : '#aaa'"
        style="width: 31%;"
        :style="cycleModel === 'daily' ? 'color: #2481C4; font-weight: bold;' : undefined"
      >
        {{ "일간" | translate }}
      </v-btn>
      <v-btn
        value="weekly"
        class="classification"
        :color="cycleModel === 'weekly' ? '#2481C4' : '#aaa'"
        style="width: 31%;"
        :style="cycleModel === 'weekly' ? 'color: #2481C4; font-weight: bold;' : undefined"
      >
        {{ "월간" | translate }}
      </v-btn>
      <v-btn
        value="monthly"
        class="classification"
        :color="cycleModel === 'monthly' ? '#2481C4' : '#aaa'"
        style="width: 31%;"
        :style="cycleModel === 'monthly' ? 'color: #2481C4; font-weight: bold;' : undefined"
      >
        {{ "연간" | translate }}
      </v-btn>
    </v-btn-toggle>

    <j-period-picker
      v-if="cycleModel === 'daily'"
      :date-end.sync="dailySearchOptions.endDate"
      :date-start.sync="dailySearchOptions.startDate"
      class="mx-3 mb-4"
      disable-future-start
      disable-future-end
    >
      <template #mid>
        <div class="mb-1 mr-3 ml-4">
          ~
        </div>
      </template>
    </j-period-picker>
    <j-period-picker
      v-else-if="cycleModel === 'weekly'"
      :date-end.sync="weeklySearchOptions.endDate"
      :date-start.sync="weeklySearchOptions.startDate"
      type="month"
      class="mx-3 mb-4"
      disable-future-start
      disable-future-end
    >
      <template #mid>
        <div class="mb-1 mr-3 ml-4">
          ~
        </div>
      </template>
    </j-period-picker>
    <div
      v-else-if="cycleModel === 'monthly'"
      style="margin: 0 12px 16px;"
    >
      <j-date-picker
        :key="datepickerIdentifier"
        v-model="monthlySearchOptions.searchYear"
        type="year"
        disable-future
        class="mx-3"
      />
    </div>

    <v-btn-toggle
      v-model="byModel"
      mandatory
      group
      style="width: 100%; margin-bottom: 12px;"
    >
      <v-btn
        value="byWorker"
        class="classification"
        :color="byModel === 'byWorker' ? '#2481C4' : '#aaa'"
        style="width: 47%;"
        :style="byModel === 'byWorker' ? 'color: #2481C4; font-weight: bold;' : undefined"
      >
        {{ "작업자 별" | translate }}
      </v-btn>
      <v-btn
        value="byAccount"
        class="classification"
        :color="byModel === 'byAccount' ? '#2481C4' : '#aaa'"
        style="width: 47%;"
        :style="byModel === 'byAccount' ? 'color: #2481C4; font-weight: bold;' : undefined"
      >
        {{ "거래처 별" | translate }}
      </v-btn>
    </v-btn-toggle>


    <!--월별 보기 버튼 시작-->
    <div
      v-if="byModel === 'byWorker' && (cycleModel === 'weekly' || cycleModel === 'monthly')"
      class="text-left"
    >
      <v-btn
        v-show="viewMode === 'worker'"
        style="width: 96%; height: 46px; border: 1px solid #2481C4; color: #2481C4; background: transparent; border-radius: 0; margin: 8px 0 24px"
        elevation="0"
        @click.stop.prevent="viewMode = 'monthly'"
      >
        <span style="position: absolute; left: 0;">
          <img src="@/assets/images/main/svg/month_back_color_icon.svg">
        </span>
        {{ "월별 보기" | translate }}
      </v-btn>
      <v-btn
        v-show="viewMode === 'monthly'"
        style="width: 96%; height: 46px; border: 1px solid #aaa; color: rgba(0, 0, 0, 0.7); background: transparent; border-radius: 0; margin: 8px 0 24px"
        elevation="0"
        @click.stop.prevent="viewMode = 'worker'"
      >
        <span style="position: absolute; left: 0;">
          <img src="@/assets/images/main/svg/month_back_gray_icon.svg">
        </span>
        {{ "작업자별 보기" | translate }}
      </v-btn>
    </div>
    <!--월별 보기 버튼 끝-->


    <!-- 통계 차트 -->
    <div
      v-show="viewMode === 'worker'"
      v-if="byModel === 'byWorker'"
      class="chart_bg mgT10"
      style="position: relative;"
    >
      <!-- 실제 차트 그려지는 부분 -->
      <div
        id="summaryChart"
        style="width: 95%; margin-left: 10px;"
        class="barchart__horizontal"
        :style="monthlyChartHeight"
      />
      <!-- legend 영역 -->
      <div class="legend_wrap">
        <div class="mx-2">
          <div class="color_box01" />
          <span>{{ "일반 A/S" |translate }}</span>
        </div>
        <div class="mx-2">
          <div class="color_box02" />
          <span>{{ "신규 설치" | translate }}</span>
        </div>
        <div class="mx-2">
          <div class="color_box07" />
          <span>{{ "해지" | translate }}</span>
        </div>
        <div class="mx-2">
          <div class="color_box03" />
          <span>{{ "미방문" | translate }}</span>
        </div>
        <div class="mx-2">
          <div class="color_box04" />
          <span>{{ "미납회수" | translate }}</span>
        </div>
        <div class="mx-2">
          <div class="color_box05" />
          <span>{{ "만기도래" | translate }}</span>
        </div>
        <div class="mx-2">
          <div class="color_box06" />
          <span>{{ "수집지연" | translate }}</span>
        </div>
      </div>

      <!-- 로딩바 -->
      <dot-loading-bar :loading="loadingByType" />
      <div style="position: absolute; width: 100%; height: 100%; left: 0; right: 0; top: 0; bottom: 0; z-index: 99" />
    </div>

    <!-- 작업자별 목록 -->
    <div v-if="viewMode === 'worker'">
      <v-expansion-panels
        v-if="!$isEmpty(summaryItemsSortedReverse)"
        :key="'a' + expansionIdentifier"
        focusable
        class="my-5"
      >
        <v-expansion-panel
          v-for="(item, i) in summaryItemsSortedReverse"
          :key="item.key + '_' + i"
        >
          <v-expansion-panel-header>
            <div class="account_title">
              <span class="mb-2 font-weight-bold">{{ item.label }}</span>
              <span class="summary">
                <span>{{ "총" | translate }}</span>
                <span>{{ item.total | number }}</span>
                <span>{{ "신규" | translate }}</span>
                <span>{{ item.newInstall | number }}</span>
                <span>{{ "해지" | translate }}</span>
                <span>{{ item.cancel | number }}</span>
              </span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <table class="contents_table_wrap mt-4">
              <tr v-if="item.normal > 0">
                <th>{{ "일반 A/S" | translate }}</th>
                <td>{{ item.normal | number }} {{ "건" | translate }}</td>
              </tr>
              <tr v-if="item.newInstall > 0">
                <th>{{ "신규 설치" | translate }}</th>
                <td>{{ item.newInstall | number }} {{ "건" | translate }}</td>
              </tr>
              <tr v-if="item.cancel > 0">
                <th>{{ "해지" | translate }}</th>
                <td>{{ item.cancel | number }} {{ "건" | translate }}</td>
              </tr>
              <tr v-if="item.collectionDelay > 0">
                <th>{{ "수집지연" | translate }}</th>
                <td>{{ item.collectionDelay | number }} {{ "건" | translate }}</td>
              </tr>
              <tr v-if="item.unpaid > 0">
                <th>{{ "미납회수" | translate }}</th>
                <td>{{ item.unpaid | number }} {{ "건" | translate }}</td>
              </tr>
              <tr v-if="item.unvisited > 0">
                <th>{{ "미방문" | translate }}</th>
                <td>{{ item.unvisited | number }} {{ "건" | translate }}</td>
              </tr>
              <tr v-if="item.expiring > 0">
                <th>{{ "만기도래" | translate }}</th>
                <td>{{ item.expiring | number }} {{ "건" | translate }}</td>
              </tr>
              <tr v-if="item.regular > 0">
                <th>{{ "정기방문" | translate }}</th>
                <td>{{ item.regular | number }} {{ "건" | translate }}</td>
              </tr>
              <tr v-if="item.total > 0">
                <th>{{ "합계" | translate }}</th>
                <td>{{ item.total | number }} {{ "건" | translate }}</td>
              </tr>
            </table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <empty-data
        v-else
        :text="'데이터가 없습니다' | translate"
      />
    </div>
    <!-- 월별 목록 -->
    <div v-else-if="viewMode === 'monthly' && (cycleModel === 'weekly' || cycleModel === 'monthly')">
      <v-expansion-panels
        v-if="!$isEmpty(monthlySummaryItemsInWeeklyGroupByPeriod)"
        :key="'b' + expansionIdentifier"
        focusable
        class="my-5"
      >
        <v-expansion-panel
          v-for="(item, i) in monthlySummaryItemsInWeeklyGroupByPeriod"
          :key="item.key"
        >
          <v-expansion-panel-header>
            <div class="account_title">
              <span class="mb-2 font-weight-bold">{{ item.text }}</span>
              <span class="summary">
                <span>{{ "총" | translate }}</span>
                <span>{{ item.summary.TOTAL | number }}</span>
                <span>{{ "신규" | translate }}</span>
                <span>{{ item.summary.NEW | number }}</span>
                <span>{{ "해지" | translate }}</span>
                <span>{{ item.summary.CANCEL | number }}</span>
              </span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="contents_table_wrap mt-4">
              <div
                v-for="(staffItem, j) in item.detail"
                :key="staffItem.staffId"
                class="mt-2"
              >
                <div style="text-align: left;">
                  {{ staffItem.staffName }}
                </div>
                <div
                  style="text-align: left; font-size: .9rem"
                  class="pl-2 mt-1"
                >
                  <span
                    v-if="staffItem.TOTAL"
                    style="width: 55px; display: inline-block;"
                  >{{ "총" | translate }} {{ staffItem.TOTAL }}</span>
                  <span
                    v-if="staffItem.NEW"
                    style="width: 55px; display: inline-block;"
                    class="color__blue"
                  >{{ "신규" | translate }} {{ staffItem.NEW }}</span>
                  <span
                    v-if="staffItem.CANCEL"
                    style="width: 55px; display: inline-block;"
                    class="color__blue"
                  >{{ "해지" | translate }} {{ staffItem.CANCEL }}</span>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <empty-data
        v-else
        :text="'데이터가 없습니다' | translate"
      />
    </div>
  </div>
</template>

<script>
    import JPeriodPicker from "@/assets/plugins/mps-vuetify-compat/pickers/JPeriodPicker";
    import JDatePicker from "@/assets/plugins/mps-vuetify-compat/pickers/JDatePicker";
    import DotLoadingBar from "@/components/part/DotLoadingBar";
    import PATH from "@/constants/api";
    import CodeOptions from "@/components/mixins/CodeOptions";
    import common from "@/assets/plugins/common/functions";
    import EmptyData from "@/components/part/EmptyData";

    /** PC 버전의 업무일지에 해당 */
    export default {
        name: "ReportWork",
        components: {EmptyData, DotLoadingBar, JDatePicker, JPeriodPicker},
        mixins: [CodeOptions],
        data() {
            return {
                viewMode: 'worker', // worker: 작업자별 보기, monthly: 월별 보기
                loadingByType: false,
                loadingMonthly: false,
                summaryItems: [],
                // 월별 목록
                monthlySummaryItemsInWeeklyView: [],
                byModel: "byWorker",
                cycleModel: "daily",
                dailySearchOptions: {
                    startDate: this.$now().startOf('day').toISO(),
                    endDate: this.$now().endOf('day').toISO(),
                },
                weeklySearchOptions: {
                    startDate: this.$now().startOf("month").toISO(),
                    endDate: this.$now().endOf("month").toISO(),
                    searchMonth: this.$now().startOf("month").toISO(),
                },
                monthlySearchOptions: {
                    searchYear: this.$now().startOf("year").toISO(),
                },
                datepickerIdentifier: 0,
                expansionIdentifier: 0,
            };
        },
        computed: {
            /**
             * 월간 리포트에서 시작일과 종료일이 같은 월을 가리키고 있다면 true, 아니면 false
             */
            weeklySearchDateJustOneMonth() {
                try {
                    const start = this.$parseDate(this.weeklySearchOptions.startDate);
                    const end = this.$parseDate(this.weeklySearchOptions.endDate);
                    return start.month === end.month;
                } catch (e) {
                    return false;
                }
            },

            monthlyChartHeight() {
                return {
                    height: (this.summaryItems.length * 30 + 180) + 'px',
                }
            },

            searchOptionsByType() {
                const searchOptions = {
                    startDate: null,
                    endDate: null,
                };
                if (this.byModel === 'byAccount') searchOptions.pageSize = 20;
                switch (this.cycleModel) {
                    case "daily":
                        searchOptions.startDate = this.$parseDate(this.dailySearchOptions.startDate).startOf("day").toISO();
                        searchOptions.endDate = this.$parseDate(this.dailySearchOptions.endDate).endOf("day").toISO();
                        break;
                    case "weekly":
                        searchOptions.startDate = this.$parseDate(this.weeklySearchOptions.startDate).startOf("month").toISO();
                        searchOptions.endDate = this.$parseDate(this.weeklySearchOptions.endDate).endOf("month").toISO();
                        break;
                    case "monthly":
                        const d2 = this.$parseDate(this.monthlySearchOptions.searchYear);
                        if (!d2) break;
                        searchOptions.startDate = d2.startOf("year").toISO();
                        searchOptions.endDate = d2.endOf("year").toISO();
                        break;
                }

                // 검색 날짜가 없다면 잘못된 것이므로 일단 당일로 처리
                if (!searchOptions.startDate) searchOptions.startDate = this.$now().startOf("day").toISO();
                if (!searchOptions.endDate) searchOptions.endDate = this.$now().endOf("day").toISO();

                return searchOptions;
            },

            summaryItemsComputed() {
                return this.summaryItems.map(e => {
                    let label = null;
                    let key = null;
                    if (this.byModel === 'byWorker') {
                        label = e.staffName || this.$translate("미배정");
                        key = e.staffId || 0;
                    } else if (this.byModel === 'byAccount') {
                        label = e.accountName;
                        key = e.accountId;
                    }
                    return {
                        ...e,
                        label: label,
                        key: key,
                        total: (e.regular || 0) + (e.newInstall || 0) + (e.cancel || 0) + (e.normal || 0) + (e.collectionDelay || 0) + (e.unpaid || 0) + (e.unvisited || 0) + (e.expiring || 0)
                    };
                });
            },

            summaryItemsSorted() {
                const items = [];
                this.$cloneDeep(this.summaryItemsComputed, items);
                items.sort((a, b) => {
                    if (this.byModel === 'byWorker') {
                        if (!a.key) return -1;
                        if (!b.key) return 1;
                    }
                    if (a.total > b.total) return 1;
                    if (a.total < b.total) return -1;
                    if (a.key > b.key) return 1;
                    if (a.key < b.key) return -1;
                    return 0;
                });
                return items;
            },

            summaryItemsSortedReverse() {
                const items = [];
                this.$cloneDeep(this.summaryItemsComputed, items);
                items.sort((a, b) => {
                    if (this.byModel === 'byWorker') {
                        if (!a.key) return 1;
                        if (!b.key) return -1;
                    }
                    if (a.total > b.total) return -1;
                    if (a.total < b.total) return 1;
                    if (a.key > b.key) return -1;
                    if (a.key < b.key) return 1;
                    return 0;
                });
                return items;
            },

            /**
             * 월간 > 월별 데이터는 테이블을 표현하기에 부적절하므로 적절하게 가공하여 테이블로 표현할 수 있도록합니다.
             */
            monthlySummaryItemsInWeeklyGroupByPeriod() {
                let arr = [];

                let start = null;
                let end = null;

                switch (this.cycleModel) {
                  case 'weekly':
                    end = this.$parseDate(this.weeklySearchOptions.startDate).startOf("month");
                    start = this.$parseDate(this.weeklySearchOptions.endDate).endOf("month");
                    break;
                  case 'monthly':
                    end = this.$parseDate(this.monthlySearchOptions.searchYear).startOf("year");
                    start = this.$parseDate(this.monthlySearchOptions.searchYear).endOf("year");
                    break;
                  default:
                    return [];
                }

                let date = start;

                // period 를 기준으로 각각의 column 데이터를 생성
                const groupedByPeriod = _.groupBy(this.monthlySummaryItemsInWeeklyView, 'period');

                // 1월씩 넘어가면서 데이터를 생성합니다.
                // 최신순입니다.
                while (date.ts > end.ts) {
                  // {0}월 에 해당하는 번역 문자열 생성
                  const text = date.year + "-" + common.pad(date.month, 2);

                  // text 로 하나의 행을 가져온다.
                  const rowData = groupedByPeriod[text];

                  if (!rowData) {
                    // 이전달로 이동
                    date = date.minus({month: 1});
                    continue;
                  }

                  /* 하나의 행을 생성합니다 */
                  const row = {
                      key: text,
                      text: text,
                      summary: {
                          TOTAL: 0,
                          NEW: 0,
                          CANCEL: 0,
                      },
                      detail: [],
                  };

                  // 하나의 행에 해당하는 상세정보
                  // 즉, 해당 월의 작업자별 현황을 나타냅니다.
                  const detail = [];

                  // staffId로 groupBy 한 오브젝트를 staffName 으로 정렬한다.
                  const groupedByStaff = _.groupBy(rowData, 'staffId');

                  // 한 기간(월)에 해당하는
                  for (let key in groupedByStaff) {
                    const arr = groupedByStaff[key];
                    // 상세에서 하나의 직원에 해당하는 A/S 작업 정보
                    const obj = { staffId: arr[0].staffId, staffName: arr[0].staffName || this.$translate("미배정"), TOTAL: 0, NEW: 0, CANCEL: 0 };
                    arr.forEach(e => {
                      switch (e.workorderReceiveType) {
                        case 'N': // 신규
                          obj.NEW += e.cnt;
                          break;
                        case 'C': // 해지
                          obj.CANCEL += e.cnt;
                          break;
                      }
                      // 합계
                      obj.TOTAL += e.cnt;
                    });
                    // detail에 추가한다.
                    detail.push(obj);
                  }

                  // 한 개월의 합계치를 구한다.
                  detail.forEach(e => {
                      row.summary.NEW += e.NEW;
                      row.summary.CANCEL += e.CANCEL;
                      row.summary.TOTAL += e.TOTAL;
                  });

                  // TOTAL 값을 기준으로 내림차순 정렬한다.
                  // 미배정인 항목은 마지막으로 한다.
                  detail.sort((a, b) => {
                    if (a.staffId == '0') return 1;
                    if (b.staffId == '0') return -1;
                    if (a.TOTAL > b.TOTAL) return 1;
                    if (a.TOTAL < b.TOTAL) return -1;
                    return 0;
                  });

                  console.log(detail);

                  row.detail = detail;

                  arr.push(row);

                  // 이전달로 이동
                  date = date.minus({month: 1});
                }

                // 합계치를 구한 후 첫 번째 행에 넣습니다.

                return arr;
            },
        },
        watch: {
            viewMode(newValue) {
                this.expansionIdentifier++;
                this.loadByMonthlyConditionally();
                if (newValue === 'worker') {
                  this.loadByType();
                  if (this.byModel === 'byWorker') {
                    // height 조정 후 차트를 그립니다.
                    this.$nextTick(() => {
                      this.drawSummary();
                    });
                  }
                }
            },

            summaryItems() {
                if (this.byModel === 'byWorker') {
                    // height 조정 후 차트를 그립니다.
                    this.$nextTick(() => {
                        this.drawSummary();
                    });
                }
            },

            cycleModel(newValue) {
                if (newValue === 'daily') {
                    this.viewMode = 'worker';
                }
                this.expansionIdentifier++;
                this.datepickerIdentifier++;
                this.loadByType();
              this.loadByMonthlyConditionally();
            },

            byModel(newValue) {
                if (newValue === 'byAccount') {
                    this.viewMode = 'worker';
                }
                this.expansionIdentifier++;
                this.loadByType();
              this.loadByMonthlyConditionally();
            },

            'dailySearchOptions.startDate'() {
                this.expansionIdentifier++;
                this.loadByType();
            },

            'dailySearchOptions.endDate'() {
                this.expansionIdentifier++;
                this.loadByType();
              this.loadByMonthlyConditionally();
            },

            'weeklySearchOptions.startDate'() {
                this.expansionIdentifier++;
                this.loadByType();
              this.loadByMonthlyConditionally();
            },

            'weeklySearchOptions.endDate'() {
                this.expansionIdentifier++;
                this.loadByType();
              this.loadByMonthlyConditionally();
            },

            "monthlySearchOptions.searchYear"() {
                this.expansionIdentifier++;
                this.loadByType();
              this.loadByMonthlyConditionally();
            },
        },
        created() {
            this.loadByType();
            this.loadOptionsForWorkorderStaff();
        },
        methods: {
            async loadByMonthlyConditionally() {
              if (this.byModel === 'byWorker' && this.viewMode === 'monthly') {
                if (this.cycleModel === 'weekly' || this.cycleModel === 'monthly')
                  this.loadByMonthlyInWeeklyView();
              }
            },

            /**
             * 작업자별 작업 통계치 로드
             */
            async loadByType() {
                this.loadingByType = true;
                let url = null;
                if (this.byModel === 'byWorker') url = PATH.REPORT.WORK.BY_TYPE;
                else if (this.byModel === 'byAccount') url = PATH.REPORT.WORK.BY_TYPE_ACCOUNT;
                if (!url) return;
                const res = await this.$request(url)
                    .setObject(this.searchOptionsByType)
                    .enqueue();
                this.summaryItems = res.data;
                this.loadingByType = false;
            },

            /**
             * 월간에서 월별 작업 통계치 로드
             */
            async loadByMonthlyInWeeklyView() {
                this.loadingMonthly = true;
                const res = await this.$request(PATH.REPORT.WORK.BY_WORK_MONTHLY)
                    .setObject(this.searchOptionsByType)
                    .enqueue();
                this.monthlySummaryItemsInWeeklyView = res.data;
                this.loadingMonthly = false;
            },

            generateSummaryChart() {
                const summaryYLables = this.summaryItemsSorted.map(e => {
                    if (this.byModel === 'byAccount') return e.label.slice(0, 5);
                    else return e.label.slice(0, 3);
                });

                const normalItems = this.summaryItemsSorted.map(e => e.normal);
                const newInstallItems = this.summaryItemsSorted.map(e => e.newInstall);
                const cancelItems = this.summaryItemsSorted.map(e => e.cancel);
                const collectionDelayItems = this.summaryItemsSorted.map(e => e.collectionDelay);
                const unpaidItems = this.summaryItemsSorted.map(e => e.unpaid);
                const unvisitedItems = this.summaryItemsSorted.map(e => e.unvisited);
                const expiringItems = this.summaryItemsSorted.map(e => e.expiring);

                let traceNormal = {
                    type: 'bar',
                    name: this.$translate("일반 A/S"),
                    // textposition: "auto",
                    // hoverinfo: 'none',
                    // text: normalItems.map(String),
                    x: normalItems,
                    y: summaryYLables,
                    orientation: 'h',
                    marker: {
                        color: 'rgba(36, 129, 196, 1)',
                        width: 1
                    },
                };
                let traceNew = {
                    type: 'bar',
                    name: this.$translate("신규 설치"),
                    // textposition: "auto",
                    // hoverinfo: 'none',
                    // text: normalItems.map(String),
                    x: newInstallItems,
                    y: summaryYLables,
                    orientation: 'h',
                    marker: {
                        color: 'rgba(246, 185, 98, 1)',
                        width: 1
                    },
                };
                let traceCancel = {
                    type: 'bar',
                    name: this.$translate("해지"),
                    // textposition: "auto",
                    // hoverinfo: 'none',
                    // text: normalItems.map(String),
                    x: cancelItems,
                    y: summaryYLables,
                    orientation: 'h',
                    marker: {
                        color: 'rgba(255, 0, 0, 1)',
                        width: 1
                    },
                };
                let traceCollectionDelay = {
                    type: 'bar',
                    name: this.$translate("수집지연"),
                    // textposition: "auto",
                    // hoverinfo: 'none',
                    // text: collectionDelayItems.map(String),
                    x: collectionDelayItems,
                    y: summaryYLables,
                    orientation: 'h',
                    marker: {
                        color: '#A0A0A0',
                        width: 1
                    },
                };
                let traceUnpaid = {
                    type: 'bar',
                    name: this.$translate("미납회수"),
                    // textposition: "auto",
                    // hoverinfo: 'none',
                    // text: unpaidItems.map(String),
                    x: unpaidItems,
                    y: summaryYLables,
                    orientation: 'h',
                    marker: {
                        color: '#EC4DA3',
                        width: 1
                    },
                };
                let traceUnvisited = {
                    type: 'bar',
                    name: this.$translate("미방문"),
                    // textposition: "auto",
                    // hoverinfo: 'none',
                    // text: unvisitedItems.map(String),
                    x: unvisitedItems,
                    y: summaryYLables,
                    orientation: 'h',
                    marker: {
                        color: '#00B9AE',
                        width: 1
                    },
                };
                let traceExpiring = {
                    type: 'bar',
                    name: this.$translate("만기도래"),
                    // textposition: "auto",
                    // hoverinfo: 'none',
                    // text: expiringItems.map(String),
                    x: expiringItems,
                    y: summaryYLables,
                    orientation: 'h',
                    marker: {
                        color: '#5200FF',
                        width: 1
                    },
                };
                let data = [traceNormal, traceNew, traceCancel, traceCollectionDelay, traceUnpaid, traceUnvisited, traceExpiring];

                let layout = {
                    // title: this.cycleStr + " " + this.rangeStr,
                    barmode: "stack",
                    xaxis: {fixedrange: true},
                    yaxis: {fixedrange: true},
                    scrollZoom: false,
                    showlegend: false,
                    margin: {
                        r: 0,
                        l: 40,
                        b: 50,
                        t: 30,
                    }
                };

                let options = {
                    displayModeBar: false,
                };

                if (this.byModel === 'byAccount') layout.margin.l = 65;

                return {data: data, layout: layout, options: options};
            },

            drawSummary() {
                const {data, layout, options} = this.generateSummaryChart();
                this.$nextTick(() => {
                    Plotly.newPlot('summaryChart', data, layout, options);
                });
            },
        },
    }
</script>

<style scoped>
    .chart_bg {
        border: 1px solid #ccc;
        border-radius: 10px;
        width: 98%;
    }

    >>> .v-btn-toggle > .v-btn.v-btn:not(:first-child) {
        border-left-width: 1px !important;
    }

    .legend_wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 25px;
        font-size: 0.8125rem;
    }

    .color_box01 {
        width: 8px;
        height: 8px;
        background: #2481C4;
        display: inline-block;
        margin-right: 4px;
    }

    .color_box02 {
        width: 8px;
        height: 8px;
        background: #F6B962;
        display: inline-block;
        margin-right: 4px;
    }

    .color_box03 {
        width: 8px;
        height: 8px;
        background: #00B9AE;
        display: inline-block;
        margin-right: 4px;
    }

    .color_box04 {
        width: 8px;
        height: 8px;
        background: #EC4DA3;
        display: inline-block;
        margin-right: 4px;
    }

    .color_box05 {
        width: 8px;
        height: 8px;
        background: #5200FF;
        display: inline-block;
        margin-right: 4px;
    }

    .color_box06 {
        width: 8px;
        height: 8px;
        background: #A0A0A0;
        display: inline-block;
        margin-right: 4px;
    }

    .color_box07 {
        width: 8px;
        height: 8px;
        background: #FF0000;
        display: inline-block;
        margin-right: 4px;
    }

    .v-expansion-panel-header {
        font-size: 1rem;
    }

    .contents_table_wrap {
        width: 100%;
        font-size: 1rem;
    }

    .contents_table_wrap tr th {
        text-align: left;
        font-weight: normal;
        height: 32px;
    }

    .contents_table_wrap tr td {
        text-align: right;
    }

    .contents_table_wrap tr:last-child th, .contents_table_wrap tr:last-child td {
        font-weight: bold;
    }

    .account_title {
        display: flex;
        flex-direction: column;
    }

    .account_title span:first-child {
        line-height: 1.2;
    }

    .summary {
        font-size: 0.9375rem;
    }

    .summary span:nth-child(odd) {
        margin-right: 4px;
    }

    .summary span:nth-child(even) {
        margin-right: 8px;
    }

    >>> .v-text-field__details {
        display: none;
    }
</style>
