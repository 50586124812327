/*********************************************************************************
 *************************** Doughnut chart css object ***************************
 *********************************************************************************/

const DoughnutType = {

    /*********************************************************************************
     *  doughnut css example
     *  사용할 때, 해당 object를 복사해서 사용해주세요.
     *********************************************************************************/

   /* 0: {
        /!**
         * 도넛츠 아이템 라벨, 원 가운데 라벨의 css를 수정합니다.
         *!/
        doughnutLabelCssObject: {
            /!**
             * size => 글자크기를 숫자로 표현해주세요. 단위는 em으로 적용됩니다.
             * color => 색상이름, RGB, 컬러코드 모두 가능
             * weight => 글자두께 normal, bold, bolder, lighter
             *!/
            itemFontSize: 0,
            itemFontColor: null,
            itemFontWeight: 'normal',
            firstLineInDoughnutFontSize: 0,
            firstLineInDoughnutFontColor: null,
            firstLineInDoughnutFontWeight: 'normal',
            secondLineInDoughnutFontSize: 0,
            secondLineInDoughnutFontColor: null,
            secondLineInDoughnutFontWeight: 'normal',
        },

        /!**
         * 도넛츠 아이템의 css를 수정합니다.
         * 데이터의 순서대로 background color를 지정해주세요.
         *!/
        doughnutDataCssObject: {
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(0, 0, 132, 0.2)',
            ],
            borderWidth: 3,
            borderColor: '#FFFFFF',
            color: '#666'
        },

        /!**
         * 도넛츠 페이지의 css를 수정합니다.
         *  layout에서는 padding 값만 수정 가능합니다.
         *!/
        doughnutCssObject: {
            layout: {
                padding: 100,
            },
            /!**
             * 도넛 두께 조절 0 - 100 까지
             *!/
            cutoutPercentage: 60,
            /!**
             * 애니메이션 효과 적용시 사용
             *!/
            // animation: {
            //     duration: 1000,
            //     easing: 'easeInBack',
            //     delay: 1000,
            //     loop: true
            // },
        },
    }*/

    /*********************************************************************************
     *  doughnut example end
     *********************************************************************************/
    1: {
        /**
         * 도넛츠 아이템 라벨, 원 가운데 라벨의 css를 수정합니다.
         */
        doughnutLabelCssObject: {
            /**
             * size => 글자크기를 숫자로 표현해주세요. 단위는 em으로 적용됩니다.
             * color => 색상이름, RGB, 컬러코드 모두 가능
             * weight => 글자두께 normal, bold, bolder, lighter
             */
            itemFontSize: 0.75,
            itemFontColor: null,
            itemFontWeight: 'normal',
            firstLineInDoughnutFontSize: 1,
            firstLineInDoughnutFontColor: '#333333',
            firstLineInDoughnutFontWeight: 'bold',
            secondLineInDoughnutFontSize: 0.875,
            secondLineInDoughnutFontColor: '#707070',
            secondLineInDoughnutFontWeight: 'normal',
        },

        /**
         * 도넛츠 아이템의 css를 수정합니다.
         * 데이터의 순서대로 background color를 지정해주세요.
         */
        doughnutDataCssObject: {
            backgroundColor: [
                '#0c98fe',
                '#cecece',
            ],
            /*borderWidth: 3,
            borderColor: '#FFFFFF',
            color: '#666'*/
        },

        /**
         * 도넛츠 페이지의 css를 수정합니다.
         *  layout에서는 padding 값만 수정 가능합니다.
         */
        doughnutCssObject: {
            layout: {
                    padding: {
                        left: 10,
                        right: 10,
                        bottom: 10,
                        top: 40
                    },
            },
            /**
             * 도넛 두께 조절 0 - 100 까지
             */
            cutoutPercentage: 70,
            /**
             * 애니메이션 효과 적용시 사용
             */
            // animation: {
            //     duration: 1000,
            //     easing: 'easeInBack',
            //     delay: 1000,
            //     loop: true
            // },
        },
    }

}


/*********************************************************************************
 ***************************   Bar chart css object    ***************************
 *********************************************************************************/

const BarType = {

    /*********************************************************************************
     *  bar css example
     *  사용할 때, 해당 object를 복사해서 사용해주세요.
     *********************************************************************************/

    0: {

        /**
         * 막대 위쪽에 보이는 숫자 라벨의 css를 수정합니다.
         */
        barLabelCssObject: {
            /**
             * size => 글자크기를 숫자로 표현해주세요. 단위는 em으로 적용됩니다.
             * color => 색상이름, RGB, 컬러코드 모두 가능
             */
            itemFontSize: 20,
            itemFontColor: null,
        },


        /**
         * 막대 아이템의 css를 수정합니다.
         * 데이터의 순서대로 background color를 지정해주세요.
         * 각각의 막대에 css를 다르게 주고 싶을 떄는 해당 항목들을 아래와 같이 작성해주세요.
         *  backgroundColor: ['rgba(255, 99, 132, 0.2)','rgba(0, 0, 132, 0.2)']
         */
        barDataCssObject: {
            backgroundColor: '#cecece',
            // borderColor: 'rgb(255, 99, 132)',
            borderWidth: 1,
            borderRadius: 0,
            /**
             * bar 두께 조절
             */
            barThickness: 20,
            maxBarThickness: 50,
            /**
             * 최소 bar 길이 조절
             */
            minBarLength: 10,
            /**
             * hover 이벤트 사용시
             */
            // hoverBackgroundColor: 'rgb(255, 99, 132)',
            // hoverBorderColor: null,


            /**
             * 라인그래프로 변경시 사용되는 key 입니다.
             */
            // type: 'line',
            // fill: false,
            // pointBorderColor: "rgba(75,192,192,1)",
            // pointBackgroundColor: "#fff",
            // pointBorderWidth: 1,
            // pointHoverRadius: 5,
            // pointHoverBackgroundColor: "rgba(75,192,192,1)",
            // pointHoverBorderColor: "rgba(220,220,220,1)",
            // pointHoverBorderWidth: 2,
        },

        /**
         * 한 차트 안에 그래프의 종류가 늘어날 때마다 barDataCssObject를 추가하여,
         * key 값만 달리해서 적용해주세요.
         */
        secondBarDataCssObject: {
            backgroundColor: '#0c98fe',
            // borderColor: 'rgb(255, 99, 132)',
            borderWidth: 1,
            borderRadius: 0,
            /**
             * bar 두께 조절
             */
            barThickness: 20,
            maxBarThickness: 50,
            /**
             * 최소 bar 길이 조절
             */
            minBarLength: 10,
            /**
             * hover 이벤트 사용시
             */
            // hoverBackgroundColor: 'rgb(0, 99, 132)',
            // hoverBorderColor: null,
        },


        /**
         * 막대차트 페이지의 css를 수정합니다.
         *  layout에서는 padding 값만 수정 가능합니다.
         */
        barCssObject: {
            layout: {
                padding: {
                    left: 10,
                    right: 10,
                    bottom: 10,
                    top: 10
                },
            },
        },

        scales: {
            /**
             * 막대차트 x축을 정의합니다.
             */
            xAxes: [{
                /**
                 * x축 라벨
                 */
                scaleLabel: {
                    display: false,
                    labelString: "x축 텍스트",
                    fontColor: "red"
                },
                /**
                 * x축 라인 (세로로 그려지는 줄)
                 */
                gridLines: {
                    display: true,
                },
                /**
                 * x축 글씨
                 */
                ticks: {
                    display: true
                },
                /**
                 * 데이터를 쌓을 것인지 여부
                 */
                stacked: true
            }],

            /**
             * 막대차트 y축을 정의합니다.
             */
            yAxes: [{
                /**
                 * 다중 y축을 사용할 때, id를 부여하면 데이터 셋에서 해당 y축을 사용하여 데이터를 생성합니다.
                 */
                id: 'A',
                position: 'left',

                /**
                 * y축 글씨
                 */
                scaleLabel: {
                    display: false,
                    labelString: "y축 텍스트 1",
                    fontColor: "green"
                },
                /**
                 * y축 라인 (세로로 그려지는 줄)
                 */
                gridLines: {
                    drawBorder: true,
                },

                /**
                 * y축 글씨
                 */
                ticks: {
                    display: true,
                    /**
                     * y축이 그려지는 사이즈
                     */
                    stepSize: 100,
                    min: 0,
                    beginAtZero: true,
                    /**
                     * y축 단위
                     */
                    callback: function (value, index, values) {
                        return value + ' 개';
                    }
                },
                stacked: true
            },

                /**
                 * 다중 y 축이 필요할 때, id와 positon 값 변경하여 사용가능
                 */

                // {
                //     id: 'B',
                //     position: 'right',
                //     scaleLabel: {
                //         display: true,
                //         labelString: "y축 텍스트 2",
                //         fontColor: "green"
                //     },
                //     gridLines: {
                //         drawBorder: true
                //     },
                //     ticks: {
                //         display: true,
                //         stepSize: 10,
                //         min: 0,
                //         beginAtZero: true,
                //         callback: function (value, index, values) {
                //             return value + ' 개';
                //         }
                //     },
                //     stacked: true,
                // }
            ]
        },
    },

    /*********************************************************************************
     *  bar example end
     *********************************************************************************/


    1: {
        barLabelCssObject: {
            itemFontSize: 5,
            itemFontColor: null,
        },


        barDataCssObject: {
            backgroundColor: 'rgba(91, 91, 91, 0.3)',
            borderColor: 'rgb(255, 99, 132)',
            // borderWidth: 1,
            // borderRadius: 0,
            barThickness: 20,
            // maxBarThickness: 50,
            minBarLength: 10,
            order: 4,
        },
        secondBarDataCssObject: {
            backgroundColor: '#0c98fe',
            borderColor: '#0c98fe',
            borderWidth: 1,
            borderRadius: 0,
            barThickness: 40,
            maxBarThickness: 50,
            minBarLength: 10,
            type: 'line',
            fill: false,
            pointBorderColor: "#0c98fe",
            pointBackgroundColor: "#0c98fe",
            pointBorderWidth: 0.2,
            pointHoverRadius: 4,
            pointHoverBackgroundColor: "#0c98fe",
            // pointHoverBorderColor: "rgba(220,220,220,1)",
            // pointHoverBorderWidth: 1,
            order: 3,
        },
        thirdBarDataCssObject: {
            type: 'line',
            backgroundColor: '#EC4DA3',
            borderColor: '#EC4DA3',
            borderWidth: 1,
            borderRadius: 0,
            barThickness: 40,
            maxBarThickness: 50,
            minBarLength: 10,
            fill: false,
            pointBorderColor: "#EC4DA3",
            pointBackgroundColor: "#EC4DA3",
            pointBorderWidth: 0.2,
            pointHoverRadius: 4,
            pointHoverBackgroundColor: "#EC4DA3",
            // pointHoverBorderColor: "rgba(220,220,220,1)",
            // pointHoverBorderWidth: 2,
            order: 2,
        },
        barCssObject: {
            layout: {
                padding: {
                    left: 10,
                    right: 10,
                    bottom: 10,
                    top: 50
                },
            },
        },
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: false,
                    labelString: "x축 텍스트",
                    fontColor: "red"
                },
                gridLines: {
                    drawBorder: true,
                },
                ticks: {
                    display: true
                },
                stacked: true
            }],
            yAxes: [{
                id: 'A',
                position: 'left',
                scaleLabel: {
                    display: false,
                    labelString: "y축 텍스트 1",
                    fontColor: "green",
                },
                gridLines: {
                    drawBorder: true,
                    color: '#aaa'
                },
                ticks: {
                    display: true,
                    stepSize: 50,
                    min: 0,
                    beginAtZero: true,
                    callback: function (value, index, values) {
                        return value + ' 개';
                    }
                },
                stacked: true
            },
                // {
                //     id: 'B',
                //     position: 'right',
                //     scaleLabel: {
                //         display: false,
                //         labelString: "y축 텍스트 2",
                //         fontColor: "green",
                //     },
                //     gridLines: {
                //         drawBorder: true,
                //         color: '#aaa'
                //     },
                //     ticks: {
                //         display: true,
                //         stepSize: 10,
                //         min: 0,
                //         beginAtZero: true,
                //         callback: function (value, index, values) {
                //             return value + ' 개';
                //         }
                //     },
                //     stacked: true,
                // }
            ]
        },
    },


    2: {
        barDataCssObject: {
            backgroundColor: 'rgba(0, 0, 132, 0.2)',
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 1,
            borderRadius: 0,
            barThickness: 40,
            maxBarThickness: 50,
            minBarLength: 10,
            hoverBackgroundColor: 'rgb(255, 99, 132)',
        },
        barCssObject: {
            layout: {
                padding: {
                    left: 10,
                    right: 10,
                    bottom: 10,
                    top: 10
                },
            },
        },
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: false,
                    labelString: "x축 텍스트",
                    fontColor: "red"
                },
                gridLines: {
                    display: true,
                },
                ticks: {
                    display: true
                },
                stacked: true
            }],
            yAxes: [{
                id: 'A',
                position: 'left',
                scaleLabel: {
                    display: false,
                    labelString: "y축 텍스트 1",
                    fontColor: "green"
                },
                gridLines: {
                    drawBorder: true,
                },
                ticks: {
                    display: true,
                    stepSize: 100,
                    min: 0,
                    beginAtZero: true,
                },
                stacked: true
            },
            ]
        },

    },

    3: {

        barLabelCssObject: {
            itemFontSize: 20,
            itemFontColor: null,
        },

        barDataCssObject: {
            backgroundColor: '#cecece',
            // borderColor: 'rgb(255, 99, 132)',
            borderWidth: 1,
            borderRadius: 0,
            barThickness: 20,
            maxBarThickness: 50,
            minBarLength: 10,
            order: 3
        },

        secondBarDataCssObject: {
            backgroundColor: '#0c98fe',
            // borderColor: 'rgb(255, 99, 132)',
            borderWidth: 1,
            borderRadius: 0,
            barThickness: 20,
            maxBarThickness: 50,
            minBarLength: 10,
            order: 2
        },

        barCssObject: {
            layout: {
                padding: {
                    left: 10,
                    right: 10,
                    bottom: 10,
                    top: 10
                },
            },
        },

        scales: {
            xAxes: [{
                scaleLabel: {
                    display: false,
                    labelString: "x축 텍스트",
                    fontColor: "red"
                },
                gridLines: {
                    display: true,
                },
                ticks: {
                    display: true
                },
                stacked: true
            }],

            yAxes: [{
                id: 'A',
                position: 'left',
                scaleLabel: {
                    display: false,
                    labelString: "y축 텍스트 1",
                    fontColor: "green"
                },
                gridLines: {
                    drawBorder: true,
                },
                ticks: {
                    display: true,
                    stepSize: 1000000,
                    min: 0,
                    beginAtZero: true,
                    callback: function (value, index, values) {
                        const a = value.toString().slice(0, -3)
                        return Number(a).toLocaleString();
                    }
                },
                stacked: false
            },
            ]
        },
    },
}

export {DoughnutType, BarType};