<template>
  <div />
</template>

<script>
    import {mapState, mapGetters, mapActions, mapMutations, KEYS} from "@/store/modules/dashboard";
    import {
      ON_LOADING_OVERLAY_CLOSE_REQUESTED,
      ON_LOADING_OVERLAY_OPEN_REQUESTED,
    } from "@/constants/listeners";

    export default {
      name: "RenewalDashboardManager",
      data() {
        return {
          loading: false,
          endDate: this.$toFormat(this.$now(), 'yyyy-MM'),
          isCreateDurationSales: true,
          isCreateDurationAccount: true,
          durations: {
            sales: 6,
            account: 6,
          },
          showWidget: {
            totalWidgetCount: 5,
            showWidgetCount: 5,
            sales: {
              id: [1, null],
              name: 'sales',
              nameKo: '매출',
              isShow: true,
              showChildCount: 4,
              /**
               * 순서대로
               * 수금현황그래프, 매출액/수금액, 수납현황, 매출추이그래프
               */
              childIds: [[6, null],[7, null],[8, null],[9, null]],
              duration: { value: 6, list: [6, 7, 8, 9, 10, 11, 12]},
              durationIds: [10, null],
              child: [true, true, true, true],
              childNames: ['수금 현황 그래프', '매출액/수금액','수납 현황', '매출추이 그래프']
            },
            account: {
              id: [2, null],
              name: 'account',
              nameKo: '임대거래처',
              isShow: true,
              showChildCount: 2,
              /**
               * 순서대로
               * 거래처현황, 거래처추이그래프
               */
              childIds: [[11, null],[12, null]],
              duration: {value: 6, list: [6, 7, 8, 9, 10, 11, 12]},
              durationIds: [13, null],
              child: [true, true],
              childNames: ['거래처 현황', '거래처 추이 그래프']
            },
            contract: {
              id: [3, null],
              name: 'contract',
              nameKo: '임대계약',
              isShow: true,
              showChildCount: 2,
              /**
               * 순서대로
               * 모델별 건수, 금액표시
               */
              childIds: [[14, null],[15, null]],
              child: [true, true],
              childNames: ['모델 별 건수', '금액 표시']
            },
            as: {
              id: [4, null],
              name: 'as',
              nameKo: 'A/S',
              isShow: true,
              showChildCount: 2,
              /**
               * 순서대로
               * A/S현황, 담당자별 현황
               */
              childIds: [[16, null],[17, null]],
              child: [true, true],
              childNames: ['A/S 현황', '담당자별 현황']
            },
            auto: {
              id: [5, null],
              name: 'auto',
              nameKo: '자동화',
              isShow: true,
              showChildCount: 2,
              /**
               * 순서대로
               * 매출표시, CMS표시, 세금계산서 표시
               */
              childIds: [[18, null],[19, null],[20, null]],
              child: [true, true, true],
              childNames: ['매출 표시', 'CMS 표시', '세금계산서 표시']
            },
          }
        }
      },
      watch: {
        endDate() {
          this.getChangeData()
        },
        async 'durations.sales'(){
            this.notifyEvent(ON_LOADING_OVERLAY_OPEN_REQUESTED);
            await this.getSalesWidgetData(this.setDateWithDuration('sales'));
            this.notifyEvent(ON_LOADING_OVERLAY_CLOSE_REQUESTED);
        },
        async 'durations.account'(){
            this.notifyEvent(ON_LOADING_OVERLAY_OPEN_REQUESTED);
            await this.getAccountWidgetData(this.setDateWithDuration('account'));
            this.notifyEvent(ON_LOADING_OVERLAY_CLOSE_REQUESTED);
        },
      },
      computed: {
        ...mapState({
          dashborad: ({dashboard}) => dashboard
        }),
        ...mapGetters({
          salesForSum: KEYS.GET_SALES_SUM_DATA,
          salesForTrend: KEYS.GET_SALES_LIST_DATA,
          accountForSum: KEYS.GET_ACCOUNT_SUM_DATA,
          accountForTrend: KEYS.GET_ACCOUNT_LIST_DATA,
          contractList: KEYS.GET_CONTRACT_LIST_DATA,
          workForSum: KEYS.GET_WORK_SUM_DATA,
          workForTrend: KEYS.GET_WORK_LIST_DATA,
          autoForSum: KEYS.GET_AUTO_SUM_DATA,
        }),
      },
      methods: {
        ...mapActions({
          updateSettings: KEYS.UPDATE_SETTING,
          getSetting: KEYS.GET_SETTING_DATA,
          getSales: KEYS.SET_SALES,
          getAccount: KEYS.SET_ACCOUNT,
          getContract: KEYS.SET_CONTRACT,
          getWork: KEYS.SET_WORK,
          getAuto: KEYS.SET_AUTO,
        }),
        ...mapMutations({
          dashboardInit: KEYS.INIT,
          dashboardSetData: KEYS.SET_DATA,
        }),

        /**
         *
         * @param key : method 이름
         * @param data : 변경할 데이터 정보
         */
        onHandleChange(key, data) {
          switch (key) {
            case 'onChangeShowWidget':
              return this.onChangeShowWidget(data);
            case 'onChangeShowWidgetChild':
              return this.onChangeShowWidgetChild(data);
          }
        },

        onChangeShowWidget({ isShow, isChange }) {
          const requestData = [];

          for (const k in isChange){
            if(isChange[k]){
              const v = {
                reportSettingId: this.showWidget[k]['id'][1],
                reportId: this.showWidget[k]['id'][0],
                value: isShow[k].value ? 'Y' : 'N'
              }
              requestData.push(v)

              // 대표값이 true로 변경 될 때, child 값도 전체 true로 변경
              if(isShow[k].value){
                this.showWidget[k]['childIds'].map(a => {
                  const v = {
                    reportSettingId: a[1],
                    reportId: a[0],
                    value: 'Y'
                  }
                  requestData.push(v)
                })

                if(this.showWidget[k].durationIds){
                  const a = this.showWidget[k].durationIds;
                  const v = {
                    reportSettingId: a[1],
                    reportId: a[0],
                    value: "6"
                  }
                  requestData.push(v)
                }
              }

            }
          }

          this.notifyEvent(ON_LOADING_OVERLAY_OPEN_REQUESTED);
          this.updateSettings({data: requestData, cb: this.getSettingData})
          this.notifyEvent(ON_LOADING_OVERLAY_CLOSE_REQUESTED);
        },


        onChangeShowWidgetChild({ widgetName, selected, duration}) {
          const requestData = [];
          const isShow = selected.includes(true);
          if (!isShow) {
            const v = {
              reportSettingId: this.showWidget[widgetName]['id'][1],
              reportId: this.showWidget[widgetName]['id'][0],
              value: 'N'
            }
            requestData.push(v)
          } else {
            this.showWidget[widgetName]['childIds'].map((a, i) => {
              const v = {
                reportSettingId: a[1],
                reportId: a[0],
                value: selected[i] ? 'Y' : 'N'
              }
              requestData.push(v)
            })

            if(this.showWidget[widgetName].durationIds){
              const a = this.showWidget[widgetName].durationIds;
              const v = {
                reportSettingId: a[1],
                reportId: a[0],
                value: duration
              }
              requestData.push(v)
            }
          }
          this.notifyEvent(ON_LOADING_OVERLAY_OPEN_REQUESTED);
          this.updateSettings({data: requestData, cb: this.getSettingData})
          this.notifyEvent(ON_LOADING_OVERLAY_CLOSE_REQUESTED);
        },

        setDesignatedMonth(startDate, endDate) {
          return {
            startDate: startDate.startOf('month').toISO(),
            endDate: endDate.endOf('month').toISO()
          };
        },

        async getSettingData() {
          await this.getSetting();
          this.showWidget = this.dashborad.setting;
          this.durations.sales = this.showWidget.sales.duration.value;
          this.durations.account = this.showWidget.account.duration.value;
        },

        async getSalesWidgetData(date) {
           await this.getSales(date);
        },

        async getAccountWidgetData(date) {
           await this.getAccount(date);
        },

        async getContractWidgetData(date) {
          await this.getContract(date);
        },

        async getWorkWidgetData(date) {
           await this.getWork(date);
        },

        async getAutoWidgetData(date) {
          await this.getAuto(date);
        },

        setDate() {
          const endDate = this.$parseFromISO(this.endDate);
          return this.setDesignatedMonth(endDate, endDate)
        },
        setDateWithDuration(key) {
          const endDate = this.$parseFromISO(this.endDate);
          return this.setDesignatedMonth(endDate.minus({months: this.durations[key] - 1}), endDate)
        },

        async getInitData() {
          const rentalcompanyId = this.userManager.user.rentalcompanyId;
          const countryCode = this.userManager.user.countryCode
          this.dashboardInit({rentalcompanyId, countryCode});
          await this.getSettingData();
          await this.getChangeData();
        },

        async getChangeData() {
          await this.getSalesWidgetData(this.setDateWithDuration('sales'));
          await this.getAccountWidgetData(this.setDateWithDuration('account'));
          await this.getContractWidgetData(this.setDate());
          await this.getWorkWidgetData(this.setDate());
          await this.getAutoWidgetData(this.setDate());
        }

      },
      created() {
        this.getInitData();
      }
    }
</script>

<style scoped>

</style>