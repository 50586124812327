<template>
  <div>
    <div>
      <div v-if="items.child[0]">
        <div class="chart_subtitle">
          {{ '거래처 현황' | translate }}
        </div>
        <!--total count-->
        <div class="total_count_wrap">
          <div class="account_count">
            <div> {{ '총 임대 거래처 수' | translate }}</div>
            <div>{{ accountForSum && accountForSum.totalAccountCount | currency }} {{ '건' | translate }}</div>
          </div>
          <div
            class="flex flex-column"
          >
            <div class="account_count">
              <div>{{ '신규 임대 거래처 수' | translate }}</div>
              <div>{{ accountForSum && accountForSum.newAccountCount | currency }} {{ '건' | translate }}</div>
            </div>
            <div class="account_count">
              <div>{{ '해지 임대 거래처 수' | translate }}</div>
              <div>{{ accountForSum && accountForSum.cancelAccountCount | currency }} {{ '건' | translate }}</div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="items.child[1]">
        <div class="chart_subtitle">
          {{ '거래처 수 추이' | translate }}
        </div>
        <div style="width: 100%; height: auto; margin: 0 auto;">
          <chart-vertical-bar
            ref="accountBarChart"
            :chart-data="accountBarChartData"
            :is-tooltip="true"
            :is-legend="true"
            :is-plugin="true"
            :required-plugin-ids="['legendMarginBottom']"
            :bar-css-object="accountBarCssType.barCssObject"
            :bar-label-css-object="accountBarCssType.barLabelCssObject"
            :bar-scales="accountBarCssType.scales"
            :step-size="stepSize"
            style="margin-top: -40px;"
          />
        </div>
      </div>

      <renewal-dashboard-textarea-component
        v-show="items.child[2]"
        :comment-info="comment"
        :date="date"
        name="account"
      />
    </div>
  </div>
</template>

<script>
    import {mapGetters, KEYS} from "@/store/modules/dashboard";
    import {BarType} from "./dashboardChartCss";
    import ChartVerticalBar from "@/components/chart/renewal-dashboard/ChartVerticalBar";
    import RenewalDashboardTextareaComponent
      from "@/views/home/dashboard/dashboard-widget/RenewalDashboardTextareaComponent";

    export default {
        name: "DashboardWidgetAccountWindow",
        components: {
          RenewalDashboardTextareaComponent,
            ChartVerticalBar
        },
        props: {
          items: {
            type: Object,
            default:() => ({ child: [true, true, true]})
          },
          duration: {
            type: Number,
            default: 6
          },
          date: {
            type: String,
            default: '2021-10'
          }
        },
        data() {
            return {
                /**
                 * 각 그래프의 CSS TYPE 을 지정해주세요.
                 */
                accountBarCssType: BarType[1],
              stepSize: 5000,
            }
        },
      computed: {
        ...mapGetters({
          accountForSum: KEYS.GET_ACCOUNT_SUM_DATA,
          accountForTrend: KEYS.GET_ACCOUNT_LIST_DATA,
        }),
        accountBarChartData() {
          if (this.accountForTrend?.graph) {
            const accountForTrend = Object.assign({}, this.accountForTrend);
            if (accountForTrend?.graph) {
              accountForTrend.graph.datasets[0] = {
                ...accountForTrend.graph.datasets[0],
                ...this.accountBarCssType.barDataCssObject,
              }
              accountForTrend.graph.datasets[1] = {
                ...accountForTrend.graph.datasets[1],
                ...this.accountBarCssType.secondBarDataCssObject,
              }
              accountForTrend.graph.datasets[2] = {
                ...accountForTrend.graph.datasets[2],
                ...this.accountBarCssType.thirdBarDataCssObject,
              }
              return accountForTrend.graph;
            } else {
              return {}
            }
          }
          return {}
        },
        comment(){
          return this.accountForSum?.comment || {};
        },
      },
      watch:{
        accountForTrend: {
          deep: true,
          handler(){
            this.stepSize = this.accountForTrend.graph.stepSize;
          }
        }
      },
        created() {
        },
        methods: {
            setLocaleString(num) {
                return num ? Number(num).toLocaleString() : '0'
            }
        }
    }
</script>

<style scoped>
    .chart_subtitle {
        font-size: 0.917rem;
        font-weight: bold;
        margin: 3% 0 2%;
        text-align: left;
    }

    .total_count_wrap {
        width: 100%;
        background-color: #f8f8f8;
        display: flex;
        flex-direction: column;
        /*align-items: center;*/
        padding: 10px;
        border-radius: 4px;
        font-size: 0.875rem;
        justify-content: space-between;
        font-weight: 500;
        margin-bottom: 5%;
    }

    .account_count {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
    }

    .total_count {
        justify-content: center;
    }
</style>