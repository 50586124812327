<template>
  <div class="height100">
    <div class="height100">
      <div class="height100">
        <div class="dashboard_right_contents_wrap">
          <div class="dashboard_top_filter_wrap">
            <div class="date_picker_wrap">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="innerDate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="innerDate"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="innerDate"
                  type="month"
                  scrollable
                  color="#0C98FE"
                  :allowed-dates="allowedDates"
                  :locale="userManager.user.countryCode"
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="#0C98FE"
                    @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="0C98FE"
                    @click="changeDate"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>

            <!--상단 우측 필터 부분-->
            <div class="filter_wrap">
              <div @click="onDialog">
                <div class="filter-icon" />
              </div>
            </div>
            <renewal-dashboard-entire-filter-modal
              :dialog="dialog"
              :show-widget="showWidget"
              @onDialogClose="onDialog"
              @onHandleChange="onHandleChange"
            />
          </div>

          <empty-data
            v-if="showWidget.showWidgetCount === 0"
            :text="'필터를 선택해주세요.' | translate"
          />

          <!--위젯-->
          <dashboard-widget-wrap
            v-if="showWidget.sales.isShow"
            class="dashboard_right_content float-left"
            style="height: auto"
            :icon="'dashboard_sale_icon.svg'"
            :title="'매출' | translate"
            :items="showWidget.sales"
            :duration="durations.sales"
            :show-set-duration-target="3"
            @onHandleChange="onHandleChange"
          >
            <dashboard-widget-sales-window
              ref="sales"
              :items="showWidget.sales"
              :duration="durations.sales"
              :date="endDate"
            />
          </dashboard-widget-wrap>


          <dashboard-widget-wrap
            v-if="showWidget.account.isShow"
            class="dashboard_right_content"
            :icon="'dashboard_account_icon.svg'"
            :title="'임대 거래처' | translate"
            :items="showWidget.account"
            :duration="durations.account"
            :show-set-duration-target="1"
            @onHandleChange="onHandleChange"
          >
            <dashboard-widget-account-window
              ref="account"
              :items="showWidget.account"
              :duration="durations.account"
              :date="endDate"
            />
          </dashboard-widget-wrap>


          <dashboard-widget-wrap
            v-if="showWidget.as.isShow"
            class="dashboard_right_content"
            :icon="'dashboard_as_icon.svg'"
            :title="'A/S' | translate"
            :items="showWidget.as"
            :show-set-duration-target="null"
            @onHandleChange="onHandleChange"
          >
            <dashboard-widget-as-window
              ref="as"
              :items="showWidget.as"
              :date="endDate"
            />
          </dashboard-widget-wrap>


          <dashboard-widget-wrap
            v-if="showWidget.contract.isShow"
            class="dashboard_right_content"
            :icon="'dashboard_contract_icon.svg'"
            :title="'장비별 임대현황' | translate"
            :items="showWidget.contract"
            :show-set-duration-target="null"
            @onHandleChange="onHandleChange"
          >
            <dashboard-widget-contract-window
              :items="showWidget.contract"
              :date="endDate"
            />
          </dashboard-widget-wrap>


          <dashboard-widget-wrap
            v-if="showWidget.auto.isShow"
            class="dashboard_right_content"
            :icon="'dashboard_automation_icon.svg'"
            :title="'자동화' | translate"
            :items="showWidget.auto"
            :show-set-duration-target="null"
            @onHandleChange="onHandleChange"
          >
            <dashboard-widget-automation-window
              :items="showWidget.auto"
              :date="endDate"
            />
          </dashboard-widget-wrap>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import RenewalDashboardManager from "./RenewalDashboardManager";
    import DashboardWidgetWrap from "./dashboard-widget/DashboardWidgetWrap";
    import DashboardWidgetSalesWindow from "./dashboard-widget/DashboardWidgetSalesWindow";
    import DashboardWidgetAccountWindow from "./dashboard-widget/DashboardWidgetAccountWindow";
    import DashboardWidgetAsWindow from "./dashboard-widget/DashboardWidgetAsWindow";
    import DashboardWidgetContractWindow from "./dashboard-widget/DashboardWidgetContractWindow";
    import DashboardWidgetAutomationWindow from "./dashboard-widget/DashboardWidgetAutomationWindow";
    import RenewalDashboardEntireFilterModal
        from "./RenewalDashboardEntireFilterModal";
    import EmptyData from "@/components/part/EmptyData";

    export default {
        name: "RenewalDashboardWindow",
        components: {
            DashboardWidgetWrap,
            DashboardWidgetSalesWindow,
            DashboardWidgetAccountWindow,
            DashboardWidgetAsWindow,
            DashboardWidgetContractWindow,
            DashboardWidgetAutomationWindow,
            RenewalDashboardEntireFilterModal,
            EmptyData
        },
        mixins: [RenewalDashboardManager],
        props: {},
        data() {
            return {
              dialog: false,
              innerDate: this.$toFormat(this.$now(), 'yyyy-MM'),
                menu: false,
                modal: false,
                salesChartUrl: null,
                salesBarChartUrl: null,
                accountBarChartUrl: null,
                salesHorizontalBarChartUrl: null,
            }
        },
        methods: {
          changeDate(){
            this.$refs.dialog.save(this.innerDate)
            this.endDate = this.innerDate;
          },
          allowedDates(val) {
            if (this.$now().toISODate() >= val && this.$parseDate('2021-09-30').toISODate() < val) {
              return true
            }
          },

            onDialogClose() {
                this.$emit('onDialogClose')
            },

            onDialog() {
                this.dialog = !this.dialog;
            },

        }
    }
</script>

<style scoped>

    .dashboard_top_filter_wrap {
        display: flex;
        justify-content: space-between;
    }

    .date_picker_wrap .v-input {
        margin: 0;
        padding: 0;
    }

    .date_picker_wrap >>> .v-text-field__details {
        display: none;
    }

    .date_picker_wrap >>> .v-text-field input {
        padding: 4px;
        background-image: url(/img/dashboard_arrow.svg);
        background-repeat: no-repeat;
        background-position: 92% center;
        background-size: 8px;
        font-size: 0.917rem;
    }

    .date_picker_wrap >>> .v-text-field__slot {
        background-color: white;
        border: 1px solid #ccc;
        width: 112px;
        height: 34px;
        border-radius: 4px;
        margin-right: 2.8%;
    }

    .date_picker_wrap >>> .v-input__slot:before {
        display: none;
    }

    .date_picker_wrap >>> .v-input__slot:after {
        display: none;
    }

    .filter_wrap {
        display: flex;
        align-items: center;
       /* padding: 2px 8px;
        !*width: 130px;*!
        height: 34px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        justify-content: space-between;
        margin-bottom: 4px;*/
    }

    .filter_wrap div {
        width: 30px;
        height: 30px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }


    .filter_wrap div img {
        width: 100%;
    }

    .dashboard_right_contents_wrap {
        width: 100%;
        height: auto;
        min-height: 100%;
        padding: 12px;
        background: #F8F9FB;
    }

    .dashboard_right_content {
        display: inline-block;
        vertical-align: top;
        /*height: 572px;*/
        /*min-height: 412px;*/
    }

    * {
        font-family: "Noto Sans KR", sans-serif;
    }

    .printer-icon {
        width: 18px;
        height: 18px;
        background-image: url("/img/dashboard/dashboard_print_icon.svg");
    }

    .printer-icon:hover {
        background-image: url("/img/dashboard/dashboard_print_color_icon.svg");
    }

    .mail-icon {
        width: 18px;
        height: 18px;
        background-image: url("/img/dashboard/dashboard_mail_icon.svg");
    }

    .mail-icon:hover {
        background-image: url("/img/dashboard/dashboard_mail_color_icon.svg");
    }

    .filter-icon {
        width: 18px;
        height: 18px;
        background-image: url("/img/dashboard/dashboard_filter_icon.svg");
        background-size: 26px;
    }

    .filter-icon:hover {
        background-image: url("/img/dashboard/dashboard_filter_color_icon.svg");
    }

</style>