<template>
  <div>
    <div style="margin-top: 20px;">
      <dashboard-widget-contract-table
        :show-info="items"
        :header="tableHeader"
        :items="tableItems"
        :is-show-all="isShowAll"
        @onChangeIsShowAll="onChangeIsShowAll"
      />
    </div>
    <renewal-dashboard-textarea-component
      v-show="items.child[2]"
      :comment-info="comment"
      :date="date"
      name="contract"
    />
  </div>
</template>

<script>
    import {mapGetters, KEYS} from "@/store/modules/dashboard";
    import DashboardWidgetContractTable from "../dashboard-widget/DashboardWidgetContractTable";
    import RenewalDashboardTextareaComponent
      from "@/views/home/dashboard/dashboard-widget/RenewalDashboardTextareaComponent";

    export default {
        name: "DashboardWidgetContractWindow",
        components: {
            DashboardWidgetContractTable,
          RenewalDashboardTextareaComponent
        },
        props: {
            items: {
              type: Object,
              default: () => ({})
            },
            duration: {
              type: null,
              default: null
            },
          date: {
            type: String,
            default: '2021-10'
          }
        },
        data() {
            return {
              isShowAll: false,
            }
        },
        computed: {
            ...mapGetters({
                contractList: KEYS.GET_CONTRACT_LIST_DATA,
            }),
            tableHeader(){
                const name = this.$translate('모델명')
                const contract = this.$translate('계약건')
                const newContract = this.$translate('신규건')
                const deleteContract = this.$translate('해지건')
                const sumPrice = this.$translate('금액합산')

                if(this.items.child[0] && this.items.child[1]){
                    return [name, contract, newContract, deleteContract, sumPrice];
                } else if(this.items.child[0] && !this.items.child[1]){
                    return [name, contract, newContract, deleteContract]
                } else {
                    return [name, sumPrice ]
                }
            },
            tableItems(){
                if(this.isShowAll && this.contractList){
                  return this.contractList.data
                } else if(!this.isShowAll && this.contractList) {
                  return this.contractList.data.slice(0, 10);
                } else {
                  return [];
                }
            },
          comment(){
            return this.contractList?.comment || {};
          },
        },
        methods: {
          onChangeIsShowAll(){
            this.isShowAll = !this.isShowAll
          }
        }
    }
</script>

<style scoped>
    .chart_subtitle {
        font-size: 0.917rem;
        font-weight: 500;
        margin: 3% 0 2%;
    }

    .total_count_wrap {
        width: 100%;
        background-color: #f8f8f8;
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 4px;
        font-size: 0.875rem;
        justify-content: space-between;
        font-weight: 500;
        margin-bottom: 5%;
        margin-top: 3%;
    }

    .total_count_wrap div:first-child {
        margin-right: 8px;
    }

    .total_count {
        justify-content: center;
    }
</style>