<template>
  <div style="padding: 8px 16px;">
    <v-btn-toggle
      v-model="cycleModel"
      group
      style="width: 100%; margin-bottom: 12px;"
      mandatory
    >
      <v-btn
        value="daily"
        class="classification"
        :color="cycleModel === 'daily' ? '#2481C4' : '#aaa'"
        style="width: 31%;"
        :style="cycleModel === 'daily' ? 'color: #2481C4; font-weight: bold;' : undefined"
      >
        {{ "일간" | translate }}
      </v-btn>
      <v-btn
        value="monthly"
        class="classification"
        :color="cycleModel === 'monthly' ? '#2481C4' : '#aaa'"
        style="width: 31%;"
        :style="cycleModel === 'monthly' ? 'color: #2481C4; font-weight: bold;' : undefined"
      >
        {{ "월간" | translate }}
      </v-btn>
      <v-btn
        value="yearly"
        class="classification"
        :color="cycleModel === 'yearly' ? '#2481C4' : '#aaa'"
        style="width: 31%;"
        :style="cycleModel === 'yearly' ? 'color: #2481C4; font-weight: bold;' : undefined"
      >
        {{ "연간" | translate }}
      </v-btn>
    </v-btn-toggle>

    <j-period-picker
      v-if="cycleModel === 'daily'"
      :date-end.sync="dailySearchOptions.endDate"
      :date-start.sync="dailySearchOptions.startDate"
      class="mx-3 mb-1"
      disable-future-start
      disable-future-end
    >
      <template #mid>
        <div class="mb-1 mr-3 ml-4">
          ~
        </div>
      </template>
    </j-period-picker>
    <div
      v-else-if="cycleModel === 'monthly'"
      style="margin: 0 12px 4px;"
    >
      <j-date-picker
        :key="datepickerIdentifier"
        v-model="monthlySearchOptions.searchMonth"
        type="month"
        disable-future
        class="mx-3"
      />
    </div>
    <div
      v-else-if="cycleModel === 'yearly'"
      style="margin: 0 12px 4px;"
    >
      <j-date-picker
        :key="datepickerIdentifier"
        v-model="yearlySearchOptions.searchYear"
        type="year"
        disable-future
        class="mx-3"
      />
    </div>

    <v-expansion-panels
      v-model="listModel"
      focusable
      class="mb-5"
    >
      <v-expansion-panel
        v-for="(item,i) in listComputed"
        :key="i"
      >
        <v-expansion-panel-header class="font-weight-bold">
          {{ item }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!--현황 시작-->
          <table
            v-if="listModel === 0"
            :key="'tab0'"
            class="status_table_wrap mt-4"
          >
            <tr>
              <th>{{ "총 거래처" | translate }}</th>
              <td v-if="statusCompany.totalAccountCount">
                {{ statusCompany.totalAccountCount | number }} {{ "건" | translate }}
              </td>
              <td v-else>
                -
              </td>
            </tr>
            <tr>
              <th>{{ "총 임대수" | translate }}</th>
              <td v-if="statusCompany.totalContractPrinter">
                {{ statusCompany.totalContractPrinter | number }} {{ "건" | translate }}
              </td>
              <td v-else>
                -
              </td>
            </tr>
            <tr>
              <th>{{ '총 매출' | translate }}</th>
              <td v-if="statusCompany.estimatedBillSum && salesPermission">
                {{ statusCompany.estimatedBillSum | currency }} {{ "원" | translate }}
              </td>
              <td v-else>
                {{ salesPermission ? '-' : 'HIDDEN' }}
              </td>
            </tr>
            <tr>
              <th>{{ "신규 거래처" | translate }}</th>
              <td v-if="statusCompany.newAccountOfMonth">
                {{ statusCompany.newAccountOfMonth | number }} {{ "건" | translate }}
              </td>
              <td v-else>
                -
              </td>
            </tr>
            <tr>
              <th>{{ "신규 임대" | translate }}</th>
              <td v-if="statusCompany.newContractPrinterOfMonth">
                {{ statusCompany.newContractPrinterOfMonth | number }} {{ "건" | translate }}
              </td>
              <td v-else>
                -
              </td>
            </tr>
            <tr>
              <th>{{ "해지" | translate }}</th>
              <td v-if="statusCompany.contractPrinterCancel">
                {{ statusCompany.contractPrinterCancel | number }} {{ "건" | translate }}
              </td>
              <td v-else>
                -
              </td>
            </tr>
          </table>
          <!--현황 끝-->

          <!--매출 시작-->
          <table
            v-if="listModel === 1"
            :key="'tab1'"
            class="sales_table_wrap mt-4"
          >
            <tr>
              <th>{{ "요청금액" | translate }}</th>
              <td v-if="!salesPermission">
                {{ salesPermission ? '-' : 'HIDDEN' }}
              </td>
              <td v-else>
                {{ formStr($translate("{0}원"), $number(statusSales.reqPrice) + " ") }}
              </td>
            </tr>
            <tr>
              <th>{{ "처리금액" | translate }}</th>
              <td v-if="!salesPermission">
                {{ salesPermission ? '-' : 'HIDDEN' }}
              </td>
              <td
                v-else
                style="color:#0C98FE"
              >
                {{ formStr($translate("{0}원"), $number(statusSales.price) + " ") }}
              </td>
            </tr>
            <tr>
              <th>{{ "미납" | translate }}</th>
              <td v-if="!salesPermission">
                {{ salesPermission ? '-' : 'HIDDEN' }}
              </td>
              <td
                v-else
                style="color:#EC4DA3"
              >
                {{ formStr($translate("{0}원"), $number(statusSales.balance) + " ") }}
              </td>
            </tr>
            <tr>
              <th>{{ "기타수납" | translate }}</th>
              <td v-if="!salesPermission">
                {{ salesPermission ? '-' : 'HIDDEN' }}
              </td>
              <td v-else>
                {{ $number(statusSales.etcProcessPrice) }} {{ '원' | translate }}
              </td>
            </tr>
            <tr v-if="userManager.countryFeature('KR')">
              <th>CMS {{ '처리예정'| translate }}</th>
              <td v-if="!salesPermission">
                {{ salesPermission ? '-' : 'HIDDEN' }}
              </td>
              <td v-else>
                {{ $number(statusSales.cmsProcessPrice) }} {{ '원' | translate }}
              </td>
            </tr>
          </table>
          <!--매출 끝-->

          <!--A/S 시작-->
          <table
            v-if="listModel === 2"
            :key="'tab2'"
            class="as_table_wrap mt-4"
          >
            <tr>
              <th>{{ "신규" | translate }}</th>
              <td>{{ statusAsComputed.new.count | number }} {{ "건" | translate }}</td>
            </tr>
            <tr>
              <th>{{ "해지" | translate }}</th>
              <td>{{ statusAsComputed.cancel.count | number }} {{ "건" | translate }}</td>
            </tr>
            <!--            <tr v-if="!$isEmpty(statusAsComputed.new.contentsSplited)">-->
            <!--              <td class="text-left pl-2 py-1">-->
            <!--                <div-->
            <!--                  v-for="(content, index) in statusAsComputed.new.contentsSplited"-->
            <!--                  :key=" index"-->
            <!--                  style="max-width: 100%;"-->
            <!--                  class="ellipsis"-->
            <!--                >-->
            <!--                  - {{ content }}-->
            <!--                </div>-->
            <!--              </td>-->
            <!--            </tr>-->
            <tr>
              <th>A/S</th>
              <td>{{ statusAsComputed.as.count | number }} {{ "건" | translate }}</td>
            </tr>
          </table>
          <!--A/S 끝-->

          <!--CMS 시작-->
          <table
            v-if="listModel === 3"
            :key="'tab3'"
            class="cms_table_wrap mt-4"
          >
            <tr>
              <th>{{ '요청'| translate }}</th>
              <td v-if="!salesPermission">
                {{ salesPermission ? '-' : 'HIDDEN' }}
              </td>
              <td v-else>
                {{ statusCms.callAmount | number }} {{ '원'| translate }}
              </td>
              <td>{{ statusCms.callCount | number }} {{ '건'| translate }}</td>
            </tr>
            <tr>
              <th>{{ '처리중'| translate }}</th>
              <td v-if="!salesPermission">
                {{ salesPermission ? '-' : 'HIDDEN' }}
              </td>
              <td v-else>
                {{ statusCms.readyAmount | number }} {{ '원'| translate }}
              </td>
              <td>{{ statusCms.readyCount | number }} {{ '건'| translate }}</td>
            </tr>
            <tr>
              <th>{{ '완료'| translate }}</th>
              <td v-if="!salesPermission">
                {{ salesPermission ? '-' : 'HIDDEN' }}
              </td>
              <td v-else>
                {{ statusCms.successAmount | number }} {{ '원'| translate }}
              </td>
              <td>{{ statusCms.successCount | number }} {{ '건'| translate }}</td>
            </tr>
            <tr>
              <th>{{ '실패'| translate }}</th>
              <td v-if="!salesPermission">
                {{ salesPermission ? '-' : 'HIDDEN' }}
              </td>
              <td v-else>
                {{ statusCms.failAmount | number }} {{ '원'| translate }}
              </td>
              <td>{{ statusCms.failCount | number }} {{ '건'| translate }}</td>
            </tr>
          </table>
          <!--CMS 끝-->

          <!--세금계산서서 시작-->
          <table
            v-if="listModel === 4"
            :key="'tab4'"
            class="tax_table_wrap mt-4"
          >
            <tr>
              <th>{{ '요청'| translate }}</th>
              <td v-if="!salesPermission">
                {{ salesPermission ? '-' : 'HIDDEN' }}
              </td>
              <td v-else>
                {{ statusTax.callAmount | number }} {{ '원'| translate }}
              </td>
              <td>{{ statusTax.callCount | number }} {{ '건'| translate }}</td>
            </tr>
            <tr>
              <th>{{ '처리중'| translate }}</th>
              <td v-if="!salesPermission">
                {{ salesPermission ? '-' : 'HIDDEN' }}
              </td>
              <td v-else>
                {{ statusTax.readyAmount | number }} {{ '원'| translate }}
              </td>
              <td>{{ statusTax.readyCount | number }} {{ '건'| translate }}</td>
            </tr>
            <tr>
              <th>{{ '완료'| translate }}</th>
              <td v-if="!salesPermission">
                {{ salesPermission ? '-' : 'HIDDEN' }}
              </td>
              <td v-else>
                {{ statusTax.successAmount | number }} {{ '원'| translate }}
              </td>
              <td>{{ statusTax.successCount | number }} {{ '건'| translate }}</td>
            </tr>
            <tr>
              <th>{{ '실패'| translate }}</th>
              <td v-if="!salesPermission">
                {{ salesPermission ? '-' : 'HIDDEN' }}
              </td>
              <td v-else>
                {{ statusTax.failAmount | number }} {{ '원'| translate }}
              </td>
              <td>{{ statusTax.failCount | number }} {{ '건'| translate }}</td>
            </tr>
          </table>
          <!--세금계산서 끝-->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
    import JPeriodPicker from "@/assets/plugins/mps-vuetify-compat/pickers/JPeriodPicker";
    import JDatePicker from "@/assets/plugins/mps-vuetify-compat/pickers/JDatePicker";
    import {translationManager} from "@/assets/plugins/mps-common/translation/translation-manager";
    import {PATH, CODE} from "@/constants";
    import userManager from "@/assets/plugins/mps-common/user/user-manager";
    import Permissions from "@/constants/permissions";

    /** PC 버전의 일계표에 해당 */
    export default {
        name: "ReportStatus",
        components: {JPeriodPicker, JDatePicker},
        data() {
            return {
                cycleModel: "daily",
                listModel: 0,
                dailySearchOptions: {
                    startDate: this.$now().startOf('day').toISO(),
                    endDate: this.$now().endOf('day').toISO(),
                },
                monthlySearchOptions: {
                    searchMonth: this.$now().startOf("month").toISO(),
                },
                yearlySearchOptions: {
                    searchYear: this.$now().startOf("year").toISO(),
                },
                statusCompany: {},
                statusSales: {},
                statusAs: [],
                statusCms: {},
                statusTax: {},
                loadingStatusCompany: false,
                loadingStatusSales: false,
                loadingStatusAs: false,
                loadingStatusCms: false,
                loadingStatusTax: false,
                datepickerIdentifier: 0,
            }
        },
        computed: {
            searchOptionsByType() {
                const searchOptions = {
                    startDate: null,
                    endDate: null,
                };
                switch (this.cycleModel) {
                    case "daily":
                        searchOptions.startDate = this.dailySearchOptions.startDate;
                        searchOptions.endDate = this.dailySearchOptions.endDate;
                        searchOptions.cycleModel = "daily" ;
                        break;
                    case "monthly":
                        const d1 = this.$parseDate(this.monthlySearchOptions.searchMonth);
                        if (!d1) break;
                        searchOptions.startDate = d1.startOf("month").toISO();
                        searchOptions.endDate = d1.endOf("month").toISO();
                        searchOptions.cycleModel = "monthly" ;
                          break;
                    case "yearly":
                        const d2 = this.$parseDate(this.yearlySearchOptions.searchYear);
                        if (!d2) break;
                        searchOptions.startDate = d2.startOf("year").toISO();
                        searchOptions.endDate = d2.endOf("year").toISO();
                        searchOptions.cycleModel = "yearly" ;
                        break;
                }

                // 검색 날짜가 없다면 잘못된 것이므로 일단 당일로 처리
                if (!searchOptions.startDate) searchOptions.startDate = this.$now().startOf("day").toISO();
                if (!searchOptions.endDate) searchOptions.endDate = this.$now().endOf("day").toISO();

                return searchOptions;
            },

            listComputed() {
                let list = [
                    translationManager.translate("현황"),
                    translationManager.translate("매출"),
                    translationManager.translate("A/S"),
                ];
                if (this.$userManager.countryFeature('KR')) {
                    list.push("CMS");
                    list.push(translationManager.translate("세금계산서"));
                }
                return list;
            },

          /**
           * 배열 형태로 떨어진 작업 현황을 workorder_receive_type 에 따라 새로운 오브젝트로 구성합니다.
           */
          statusAsComputed() {
            const obj = {
              new: {},
              cancel: {},
              collectingDelayed: {},
              receivable: {},
              unvisited: {},
              expiring: {},
              as: {
                count: 0,
                contentsSplited: [],
              },
            };

            if (this.$isEmpty(this.statusAs)) return obj;

            this.statusAs.forEach(e => {
              let contents = (e.contents || "").trim();

              if (!!contents && contents.length > 0) {
                contents = contents.substring(0, contents.length - 3);
              }

              const splited = contents.trim().split("!@#,");

              const item = {
                count: e.count,
                contentsSplited: splited.slice(0, 5),
                contentsCount: splited.length,
                contentsCountAdjusted: splited.length - splited.slice(0, 5).length,
              };

              if (!this.$isEmpty(item.contentsSplited)) {
                item.contentsSplited = item.contentsSplited.map(e => (e || "").trim()).filter(e => !!e);
              }

              switch (e.workReceiveType) {
                case CODE.WORKORDER_RECEIVE_TYPE.NEW: // 신규
                  obj.new = item;
                  break;
                case CODE.WORKORDER_RECEIVE_TYPE.CANCEL: // 해지
                  obj.cancel = item;
                  break;
                case CODE.WORKORDER_RECEIVE_TYPE.COLLECTING_DELAY_VISIT: // 수집지연
                  obj.collectingDelayed = item;
                  obj.as.count += item.count; // 신규, 해지 제외하고 A/S 건으로 통합
                  obj.as.contentsSplited = obj.as.contentsSplited.concat(item.contentsSplited);
                  break;
                case CODE.WORKORDER_RECEIVE_TYPE.RECEIVABLE_VISIT: // 미납회수
                  obj.receivable = item;
                  obj.as.count += item.count;
                  obj.as.contentsSplited = obj.as.contentsSplited.concat(item.contentsSplited);
                  break;
                case CODE.WORKORDER_RECEIVE_TYPE.LONG_TERM_UNVISITED: // 장기미방문
                  obj.unvisited = item;
                  obj.as.count += item.count;
                  obj.as.contentsSplited = obj.as.contentsSplited.concat(item.contentsSplited);
                  break;
                case CODE.WORKORDER_RECEIVE_TYPE.EXPIRING: // 만기도래
                  obj.expiring = item;
                  obj.as.count += item.count;
                  obj.as.contentsSplited = obj.as.contentsSplited.concat(item.contentsSplited);
                  break;
                default:
                  obj.as.count += item.count;
                  break;
              }
            });
            return obj;
          },
          salesPermission() {
            return userManager.hasPermission(Permissions.EXPOSE_BILLING_MANAGE)
          },
        },
        watch: {
            cycleModel() {
                this.datepickerIdentifier++;
                this.loadDefault();
            },

            'dailySearchOptions.startDate'() {
                this.loadDefault();
            },

            'dailySearchOptions.endDate'() {
                this.loadDefault();
            },

            "monthlySearchOptions.searchMonth"() {
                this.loadDefault();
            },

            "yearlySearchOptions.searchYear"() {
                this.loadDefault();
            },
        },
        created() {
            this.loadDefault();
        },
        methods: {
          /**
           * 일계표 기본 현황 로드 묶음(공통 로직 묶음)
           */
          async loadDefault() {
            this.loadStatusCompany();
            this.loadStatusSales();
            this.loadStatusAs();
            if (this.$userManager.countryFeature('KR')) this.loadStatusCms();
            if (this.$userManager.countryFeature('KR')) this.loadStatusTax();
          },

          /**
           * 총 현황 로드
           */
          async loadStatusCompany() {
            this.loadingStatusCompany = true;
            const res = await this.$request(PATH.REPORT.STATUS.COMPANY)
                    .setObject(this.searchOptionsByType)
                    .enqueue();
            this.statusCompany = res;
            this.loadingStatusCompany = false;
          },

          /**
           * 매출 현황 로드
           */
          async loadStatusSales() {
            this.loadingStatusSales = true;
            const res = await this.$request(PATH.REPORT.STATUS.SALES)
                    .setObject(this.searchOptionsByType)
                    .enqueue();
            this.statusSales = res;
            this.loadingStatusSales = false;
          },

          /**
           * 작업 현황 로드
           */
          async loadStatusAs() {
            this.loadingStatusAs = true;
            const res = await this.$request(PATH.REPORT.STATUS.AS)
                    .setObject(this.searchOptionsByType)
                    .enqueue();
            this.statusAs = res.data;
            this.loadingStatusAs = false;
          },

          /**
           * CMS 현황 로드
           */
          async loadStatusCms() {
            this.loadingStatusCms = true;
            const res = await this.$request(PATH.REPORT.STATUS.CMS)
                    .setObject(this.searchOptionsByType)
                    .enqueue();
            this.statusCms = res;
            this.loadingStatusCms = false;
          },

          /**
           * TAX 현황 로드
           */
          async loadStatusTax() {
            this.loadingStatusTax = true;
            const res = await this.$request(PATH.REPORT.STATUS.TAX)
                    .setObject(this.searchOptionsByType)
                    .enqueue();
            this.statusTax = res;
            this.loadingStatusTax = false;
          },
        },
    }
</script>

<style scoped>
    >>> .v-btn-toggle > .v-btn.v-btn:not(:first-child) {
        border-left-width: 1px !important;
    }

    .v-expansion-panel--active > .v-expansion-panel-header {
        min-height: 48px;
    }

    .v-expansion-panel-header {
        font-size: 1rem;
    }

    /*현황 시작*/
    .status_table_wrap {
        width: 100%;
        font-size: 0.9375rem;
    }

    .status_table_wrap th {
        text-align: left;
        height: 32px;
        font-weight: normal;
    }

    .status_table_wrap td {
        text-align: right;
    }

    /*현황 끝*/

    /*매출 시작*/
    .sales_table_wrap {
        width: 100%;
        font-size: 0.9375rem;
    }

    .sales_table_wrap th {
        text-align: left;
        height: 32px;
        font-weight: normal;
    }

    .sales_table_wrap td {
        text-align: right;
    }

    /*매출 끝*/

    /*A/S 시작*/
    .as_table_wrap {
        width: 100%;
        font-size: 0.9375rem;
    }

    .as_table_wrap th {
        text-align: left;
        height: 32px;
        font-weight: normal;
    }

    .as_table_wrap td {
        text-align: right;
    }

    /*A/S 끝*/

    /*CMS 시작*/
    .cms_table_wrap {
        width: 100%;
        font-size: 0.9375rem;
    }

    .cms_table_wrap th {
        text-align: left;
        font-weight: normal;
        height: 32px;
    }

    .cms_table_wrap tr td:nth-child(2) {
        text-align: right;
        width: 44%;
    }

    .cms_table_wrap td:last-child {
        text-align: right;
    }

    /*CMS 끝*/

    /*세금계산서 시작*/
    .tax_table_wrap {
        width: 100%;
        font-size: 0.9375rem;
    }

    .tax_table_wrap th {
        text-align: left;
        font-weight: normal;
        height: 32px;
    }

    .tax_table_wrap tr td:nth-child(2) {
        text-align: right;
        width: 44%;
    }

    .tax_table_wrap td:last-child {
        text-align: right;
    }

    /*세금계산서 끝*/

  /* 일간, 월간, 연간 클래스 */
</style>
