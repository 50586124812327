<template>
  <v-card
    class="mx-auto"
    outlined
    :color="item.color"
    dark
    height="130px"
    style="min-width: 33.3% !important; padding: .5rem 0 .2rem 0"
    :disabled="disabled"
    @click="onClick"
  >
    <v-list-item
      class="pa-0"
      style="height: 100% !important;"
    >
      <v-list-item-content>
        <template v-if="item.singleLine">
          <v-list-item-title
            class="white--text headline font-weight-bold"
          >
            {{ item.singleLine }}
          </v-list-item-title>
        </template>

        <template v-else>
          <v-list-item-title
            class="white--text headline font-weight-bold"
          >
            {{ item.firstLine }}
          </v-list-item-title>


          <!-- 미수금 -->
          <v-list-item-subtitle
            class="caption white--text"
          >
            {{ item.secondLine }}
          </v-list-item-subtitle>
        </template>

        <v-list-item-subtitle
          class="subtitle-1 white--text"
          style="margin-top: auto; font-size: 0.875rem !important;"
        >
          {{ item.title }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
    export default {
        name: "DashboardSummaryStatusCard",
        props: {
            item: {
                type: Object,
                default: () => {},
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            onClick() {
                this.routerManager.push({name: this.item.componentName });
            },
        }
    }
</script>

<style scoped>
  .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 0 !important;
  }


  >>>.v-list-item__content {
    padding: 10px 5px !important;
    height: 100% !important;
  }

  >>>.v-list-item__title,
  >>>.v-list-item__subtitle {
    white-space: pre-wrap !important;
    word-break: keep-all !important;
    line-height: inherit !important;
  }

  @media (max-width: 320px) {
    >>>.headline {
      font-size: 1.4rem !important;
    }

    >>>.subtitle-1 {
      font-size: 0.9rem !important;
    }

    >>>.subtitle-2 {
      font-size: 0.8rem !important;
    }
  }
</style>