
<script>
    import {Doughnut} from 'vue-chartjs'
    import ChartDoughnutPlugin from "./chart-plugin/ChartDoughnutPlugin";

    export default {
        name: "ChartDoughnut",
        extends: Doughnut,
        mixins:[ChartDoughnutPlugin],
        props: {
            chartData: {
                type: Object,
                default: null
            },
            isTooltip: {
                type: Boolean,
                default: true,
            },
            isLegend:{
                type: Boolean,
                default: true,
            },
            isPlugin:{
                type: Boolean,
                default: true,
            },
            /**
             * isPlugin 값이 true 일 때, 해당 변수에 chartDoughnutPlug 에 등록한 함수와
             * 매칭되는 plugin id 값을 넣어주세요.
             */
            requiredPluginIds:{
                type:Array,
                default:() => ([])
            },
            doughnutLabelCssObject:{
                type: null,
                default: null
            },
            doughnutCssObject: {
                type: null,
                default: null
            }
        },
        /**
         *  각 페이지에 사용할 플러그인을 등록
         *      1. chartDoughnutPlugin.vue 에 함수 등록
         *      2. 현재 페이지의 setPlugin 에서 id 값 받아서 처리 할 수 있도록 등록
         */
        data() {
            return {
                options: {
                    responsive: true,
                    tooltips: {
                        enabled: this.isTooltip,
                        callbacks: {
                            label: function(tooltipItem, data) {
                                const idx = tooltipItem.index;
                                return Number(data.datasets[0].data[idx]).toLocaleString();
                            }
                        }
                    },
                    legend: {
                        display: this.isLegend,
                        position: 'top',
                    },
                    plugins: {},
                    ...this.doughnutCssObject,
                }
            }
        },
        computed: {},
        watch: {
            chartData() {
                this.render();
            }
        },
        mounted() {
            this.setPlugin();
            this.render();
        },
        methods: {
            render() {
                this.renderChart(this.chartData, this.options);
            },
            getBase64Image(){
                return this.$data._chart.toBase64Image();
            },
            setPlugin(){
                if(this.isPlugin && this.requiredPluginIds.length > 0){
                    this.requiredPluginIds.map(id => {
                      switch (id) {
                        case 'textInDashboardDoughnut':
                            this.addPlugin({
                                id: 'textInDashboardDoughnut',
                                beforeDraw: this.textInDashboardDoughnut})
                            break;
                        case 'itemCategoryName':
                            this.addPlugin(
                                {
                                    id: 'itemCategoryName',
                                    afterDraw: this.itemCategoryName
                                })
                            break;
                      }


                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>