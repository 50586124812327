<template>
  <div class="height100">
    <renewal-dashboard-window />
  </div>
</template>

<script>
    import RenewalDashboardWindow from "./dashboard/RenewalDashboardWindow";

    export default {
        name: "Report",
        components: {
          RenewalDashboardWindow
        }

    }
</script>

<style scoped>

</style>