<template>
  <div>
    <carousel-tabs-items
      v-model="model"
      :items="carouselTabsItems"
    />
  </div>
</template>

<script>
    import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
    import ScrollStateManager from "@/assets/plugins/mps-window-mobile/ScrollStateManager";
    import AdminHomeItem from "@/views/home/AdminHomeItem";
    import ReportStatus from "@/views/home/ReportStatus";
    import ReportWork from "@/views/home/ReportWork";
    import ReportYearlyWork from "@/views/home/ReportYearlyWork";
    import Report from "@/views/home/Report";
    import WorkRecord from "@/views/home/WorkRecord";
    import ConsultNote from "@/views/home/ConsultNote.vue";

    export default {
        name: "AdminHome",
        components: {
        },
        extends: WindowComponent,
        mixins: [ScrollStateManager],
        data() {
            return {
                model: "home",
                carouselTabsItems: [
                  { text: this.$translate("홈"), value: "home", component: AdminHomeItem },
                  { text: this.$translate("보고서"), value: "report", component: Report },
                  { text: this.$translate("일계표"), value: "report_status", component: ReportStatus },
                  { text: this.$translate("업무일지"), value: "report_work", component: ReportWork },
                  { text: this.$translate("기간별 A/S 요약"), value: "report_yearly_work", component: ReportYearlyWork },
                  { text: this.$translate("작업일지"), value: "work_record", component: WorkRecord },
                  { text: this.$translate("상담노트"), value: "consult_note", component: ConsultNote },
                ],
            }
        },
      watch: {
        model(newValue, oldValue) {
          localStorage.setItem('homeActiveTab', newValue)
        }
      },
      created() {
        const homeActiveTab = localStorage.getItem('homeActiveTab')
        if (homeActiveTab && homeActiveTab !== '' ){
          this.model = homeActiveTab
        }
      },
    }
</script>

<style scoped>
    .v-application .title {
        font-size: 0.938rem !important;
    }

    >>> .v-slide-group__content {
        border-bottom: 2px solid #ffffff !important;
    }
</style>
