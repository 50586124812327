<template>
  <div style="padding: 8px 16px;">
    <template v-if="userManager.hasPermission(Permissions.EXPOSE_SUMMARY_SALES)">
      <!--매출현황-->
      <sales-status ref="salesStatus" />
    </template>

    <template v-if="userManager.hasPermission(Permissions.MENU_NAV_ACCOUNT)">
      <div class="mt-5">
        <dashboard-summary-status-widget ref="dashboardSummaryStatusWidget" />
      </div>
    </template>

    <template v-if="userManager.hasPermission(Permissions.MENU_NAV_WORK)">
      <!--위젯-->
      <main-as-widget ref="mainAsWidget" />

      <!--A/S 접수 버튼 -->
      <button-schedule
        ref="buttonSchedule"
        @click="onScheduleClicked"
      />

      <template v-if="userManager.hasPermission(Permissions.MENU_NAV_WORK_EACH_WORKER)">
        <div>
          <!--작업자 작업현황-->
          <workorder-status-widget ref="workorderStatusWidget" />
        </div>
      </template>
    </template>
    <template v-else>
      <empty-data :text="'A/S 권한이 없습니다' | translate" />
    </template>
  </div>
</template>

<script>
    import {NATIVE_EVENT} from "@/assets/plugins/mps-mobile-native-bridge/native-bridge";
    import SalesStatus from "@/components/widgets/SalesStatus";
    import DashboardSummaryStatusWidget from "@/components/widgets/as/DashboardSummaryStatusWidget";
    import MainAsWidget from "@/components/widgets/MainAsWidget";
    import WorkorderStatusWidget from "@/components/widgets/as/WorkorderStatusWidget";
    import ButtonSchedule from "@/components/windows/main/ButtonSchedule";
    import EmptyData from "@/components/part/EmptyData";

    export default {
        name: "AdminHomeItem",
        components: {
          EmptyData,
          ButtonSchedule, WorkorderStatusWidget, MainAsWidget, DashboardSummaryStatusWidget, SalesStatus},
        created() {
            /**
             * A/S가 접수 되었을 때 콜백 메소드 등록
             */
            this.$nativeEventBus.$on(NATIVE_EVENT.ON_NOTIFICATION_RECEIVED, this.onRefresh);
            this.onRefresh();
        },
        mounted() {
            this.onRefresh();
        },
        methods: {
            /**
             * A/S가 접수되면 호출
             * -> A/S와 관련된 컴포넌트 리프레쉬
             */
            onRefresh() {
                if (!!this.$refs) {
                    setTimeout(() => {
                        if (this.$refs.salesStatus) this.$refs.salesStatus.refresh();
                    }, 100);
                    setTimeout(() => {
                        if (this.$refs.workorderStatusWidget) this.$refs.workorderStatusWidget.refresh();
                    }, 500);
                    setTimeout(() => {
                        if (this.$refs.dashboardSummaryStatusWidget) this.$refs.dashboardSummaryStatusWidget.refresh();
                    }, 100);
                    setTimeout(() => {
                        if (this.$refs.mainAsWidget) this.$refs.mainAsWidget.refresh();
                    }, 100);
                }
            },

            /**
             * 예약 버튼 클릭시
             */
            onScheduleClicked() {
                this.routerManager.push({name: "AsRegister"});
            },
        },
    }
</script>

<style scoped>

</style>
