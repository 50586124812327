<template>
  <div class="widget_contents_wrap">
    <div class="flex justify-content-between">
      <div class="flex">
        <div>
          <img :src="require(`@/assets/images/dashboard/${icon}`)">
        </div>
        <div class="contents_title">
          {{ title }}
        </div>
      </div>
      <button @click="onDialog">
        <img src="@/assets/images/dashboard/dashboard_more_icon.svg">
      </button>
    </div>
    <renewal-dashboard-filter-modal
      :dialog="dialog"
      :items="items"
      :duration="duration"
      :show-set-duration-target="showSetDurationTarget"
      @onDialogClose="onDialog"
      @onHandleChange="onHandleChange"
    />
    <slot />
  </div>
</template>

<script>
    import RenewalDashboardFilterModal from "../RenewalDashboardFilterModal";

    export default {
      name: "DashboardWidgetWrap",
      components: {
        RenewalDashboardFilterModal
      },
      props: {
        icon: {
          type: null,
          default: null,
        },
        title: {
          type: null,
          default: null,
        },
        items: Object,
        duration: Number,
        showSetDurationTarget: null,
      },
      data() {
        return {
          dialog: false,
        }
      },
      computed:{},
      methods:{
        onDialog(){
          this.dialog = !this.dialog;
        },
        onHandleChange(state){
          this.$emit('onHandleChange', 'onChangeShowWidgetChild', state)
          this.onDialog();
        }
      }
    }
</script>

<style scoped>
    .widget_contents_wrap {
        width: 100%;
        background-color: white;
        border: 1px solid #ccc;
        padding: 12px 6px 16px;
        border-radius: 5px;
        margin: 0 12px 12px 0px;
        clear: both;
    }

    .contents_title {
        font-size: 0.875rem;
        font-weight: bold;
        color: #0c98fe;
        margin-left: 5px;
    }

    button:hover {
        background-color: transparent;
        transform: scale(1.15);
    }

</style>