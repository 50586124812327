<template>
  <v-dialog
    v-model="dialog"
    max-width="300px"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <div class="widget_modal_title_wrap">
        <div>{{ items.nameKo | translate }}{{ '위젯 설정' | translate }}</div>
        <v-btn
          :ripple="false"
          icon
          @click="onDialogClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="widget_modal_info">
        {{ '보고싶은 항목을 선택하세요' | translate }}
      </div>
      <renewal-dashboard-filter-modal-content
        :items="items"
        :duration="duration"
        :show-set-duration-target="showSetDurationTarget"
        @onHandleChange="onHandleChange"
      />
    </v-card>
  </v-dialog>
</template>

<script>
    import RenewalDashboardFilterModalContent from "./RenewalDashboardFilterModalContent";

    export default {
      name: "RenewalDashboardFilterModal",
      components: {
        RenewalDashboardFilterModalContent
      },
      props: {
        dialog: {
          type: Boolean,
          default: false,
        },
        items:{
          type: Object,
          default: () => ({}),
        },
        duration: {
          type: null,
          default: null,
        },
        showSetDurationTarget: {
          type: null,
          default: null,
        },
      },
      methods: {
        onDialogClose() {
          this.$emit('onDialogClose')
        },
        onHandleChange(state){
          this.$emit('onHandleChange', state)
        }
      }
    }
</script>

<style scoped>
    * {
        font-family: "Noto Sans KR", sans-serif;
    }

    .v-sheet.v-card {
        padding: 23px 35px;
        text-align: left;
    }

    .widget_modal_title_wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .widget_modal_title_wrap div {
        font-size: 1rem;
        color: #333;
        font-weight: 500;
    }

    .widget_modal_info {
        font-weight: normal;
        font-size: 0.75rem;
        color: #8e8e8e;
        margin-bottom: 14px;
    }
</style>