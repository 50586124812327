<template>
  <div class="dashboard_table_wrap">
    <table
      cellspacing="0"
      cellpadding="0"
    >
      <tr>
        <th
          v-for="(h,i) in header"
          :key="`h-${i}`"
        >
          {{ h }}
        </th>
      </tr>
      <tr
        v-for="(item,j) in items"
        :key="`c-${j}`"
      >
        <td>{{ item.userFullname }}</td>
        <td>{{ item.totalCountByWorker }}</td>
        <td>{{ item.successCount }}</td>
        <td>{{ item.theDayCompletion }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
    export default {
        name: "DashboardWidgetTable",
        props: {
            items: Array,
            header: Array,
        },
    }
</script>

<style scoped>
    .dashboard_table_wrap {
        /*height: 402px;*/
    }

    table {
        width: 100%;
        text-align: center;
        table-layout: fixed;
    }

    table tr th {
        color: #888;
        font-weight: normal;
        font-size: 0.833rem;
        border-bottom: 1px solid #ddd;
        padding-bottom: 4px;
       /* background-color: white;
        position: sticky;
        top: 0;*/
    }

    table tr td {
        color: #333;
        font-weight: 500;
        font-size: 0.833rem;
        border-bottom: 1px solid #ddd;
        padding: 4px 2px;
    }

    table tr td:first-child {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    * {
        font-family: "Noto Sans KR", sans-serif;
    }
</style>