<template>
  <div>
    <template v-if="!loading">
      <template v-if="staffStatusList.length > 0">
        <div
          class="d-flex justify-space-between align-center boder-bottom"
        >
          <span
            class="title"
            style="margin-top: 25px;"
          >
            {{ '작업자 현황' | translate }}
          </span>
        </div>
        <!--작업자 작업현황-->
        <v-scroll-x-transition group>
          <template
            v-for="(item, index) in staffStatusList"
          >
            <v-row
              :key="(item.staffId || ('index-' + index))+'-row'"
              v-ripple
              class="ma-0"
            >
              <work-status-item :item="item" />
            </v-row>
          </template>
        </v-scroll-x-transition>
      </template>
      <template v-else>
        <div
          class="d-flex justify-space-between align-center"
        >
          <span
            class="title mb-5"
            style="margin-top: 25px;"
          >
            {{ '작업자가 없습니다' | translate }}
          </span>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
    import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
    import {PATH} from "@/constants";
    import WorkStatusItem from "@/components/widgets/as/WorkStatusItem";

    export default {
        name: "WorkorderStatusWidget",
        components: {WorkStatusItem},
        extends: WindowComponent,
        mixins: [],
        data() {
            return {
                staffStatusList: [],
                loading: true,
            }
        },

        computed: {},

        created() {
            // this.loadWorkorderStaffStatus();
        },

        mounted() {
        },

        methods: {
            async loadWorkorderStaffStatus() {
                // this.loading = true;
                try {
                    const response = await this.$request(PATH.MAP_APP.GET_ALL_WORKER)
                        .setObject({})
                        .enqueue();
                    const mapped = response.data.map(item => {
                      return {
                        ...item,
                        allCnt: ((item.asWaitCnt || 0) + (item.asProcCnt || 0) + (item.asDayFinishCnt || 0) + (item.asDayCancelCnt || 0)),
                      }
                    })
                    const sorted = mapped.sort((a, b) => {
                      return b.allCnt - a.allCnt;
                    });
                    this.staffStatusList = sorted;
                } catch (error) {
                }
                this.loading = false;
            },

            refresh() {
                this.loadWorkorderStaffStatus();
            },
        }
    }
</script>

<style scoped>
    .ptr-handle {
        height: 100%;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        align-items: flex-end;
        border: magenta 1px solid;
    }
</style>
