<template>
  <div class="filter_contents_wrap">
    <div class="filter_contents">
      <div
        v-for="(n, i) in items.childNames"
        :key="`child-${i}-${n}-${items.name}`"
        class="checkbox_option_wrap"
      >
        <input
          :id="`opt-${i}-${n}-${items.name}`"
          v-model="state.selected[i]"
          type="checkbox"
          name="my-checkbox"
        >
        <label :for="`opt-${i}-${n}-${items.name}`">{{ n | translate }}</label>
      </div>
      <div
        v-if="showDuration"
        class="mt-1"
      >
        <div class="range_set_wrap">
          <div class="img_wrap">
            <img src="@/assets/images/dashboard/dashboard_calendar.png">
          </div>
          <div>{{ items.nameKo | translate }} {{ '추이 기간 변경 설정' | translate }}</div>
        </div>
        <div>
          <select v-model="state.duration">
            <option
              v-for="(d, idx) in durationList"
              :key="`duration-${idx}`"
              :value="d"
            >
              {{ d }} {{ '개월' | translate }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div
      class="complete_button"
      @click="onClickHandle"
    >
      <button>{{ '설정 완료' | translate }}</button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "RenewalDashboardFilterModalContent",
    props: {
      items:{
        type: Object,
        default: () => ({}),
      },
      duration: {
        type: null,
        default: null,
      },
      showSetDurationTarget: {
        type: null,
        default: null,
      },
    },
    data(){
      return {
        state: {
          widgetName: null,
          selected: [],
          duration: 6,
        },
      }
    },
    computed: {
      showDuration(){
        if(this.showSetDurationTarget && this.state.selected.length > 0){
          return this.state.selected[this.showSetDurationTarget]
        }
        return null;
      },
      durationList(){
        return this.items?.duration?.list || []
      }
    },
    mounted() {
      this.state.widgetName = this.items?.name;
      this.state.selected = this.items?.child.map(c => c);
      this.state.duration = this.duration;
    },
    methods:{
      onClickHandle(){
        this.$emit('onHandleChange', this.state)

      },
    }
  }
</script>

<style scoped>
    * {
        font-family: "Noto Sans KR", sans-serif;
    }

    .filter_contents_wrap {
        height: auto;
    }

    .filter_contents {
        margin-bottom: 34px;
    }

    .checkbox_option_wrap {
        display: flex;
        align-items: center;
    }

    .checkbox_option_wrap label {
        color: #707070;
    }

    input[type=checkbox] {
        display: none;
    }

    input[type=checkbox] + label {
        display: inline-block;
        cursor: pointer;
        position: relative;
        font-size: 0.875rem;
        line-height: 30px;
        padding-left: 30px !important;
    }

    input[type=checkbox] + label:before {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        position: absolute;
        left: 0px;
        /*bottom: 5px;*/
        top: 8px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: inset 0px 1px 1px 0px rgb(0, 0, 0, 0), 0px 1px 0px 0px rgb(255, 255, 255, 0.3);
        border: 1px solid #DDDDDD !important;
        box-sizing: border-box;
    }

    input[type=checkbox]:checked + label:before {
        background: url(/img/dashboard/icon_checked.png) -12px top no-repeat;
        background-size: 54px;
    }

    .range_set_wrap {
        display: flex;
        /*color: #707070;*/
    }

    .range_set_wrap div:nth-child(2) {
        font-size: 0.875rem;
    }

    .img_wrap {
        width: 18px;
        margin: 0 13px 2px 0;
    }

    .img_wrap img {
        width: 100%;
    }

    select {
        width: 173px;
        height: 29px;
        padding: 2px 4px;
        background-image: url(/img/dashboard/dashboard_arrow.svg);
        background-repeat: no-repeat;
        background-position: 92% center;
        background-size: 8px;
        cursor: pointer;
        border: 1px solid #ddd;
        font-size: 0.875rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .complete_button {
        width: 100%;
        text-align: center;
    }

    .complete_button button {
        width: 100%;
        height: 34px;
        color: white;
        background-color: #0c98fe;
        border-radius: 2px;
        font-size: 0.833rem;
    }

</style>