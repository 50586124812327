<template>
  <div style="padding: 8px 16px;">
    <div style="margin: 10px 12px;">
      <j-date-picker
        v-model="searchOptions.searchYear"
        type="year"
        disable-future
        class="mx-3"
      />
    </div>

    <v-expansion-panels
      focusable
      class="mb-5"
    >
      <!--월별 시작-->
      <v-expansion-panel>
        <v-expansion-panel-header class="font-weight-bold">
          {{ "월별" | translate }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <table class="monthly_table_wrap mt-4">
            <tr>
              <th>{{ "1월" | translate }}</th>
              <td>{{ context.January | number }} {{ "건" | translate }}</td>
            </tr>
            <tr>
              <th>{{ "2월" | translate }}</th>
              <td>{{ context.February | number }} {{ "건" | translate }}</td>
            </tr>
            <tr>
              <th>{{ "3월" | translate }}</th>
              <td>{{ context.March | number }} {{ "건" | translate }}</td>
            </tr>
            <tr>
              <th>{{ "4월" | translate }}</th>
              <td>{{ context.April | number }} {{ "건" | translate }}</td>
            </tr>
            <tr>
              <th>{{ "5월" | translate }}</th>
              <td>{{ context.May | number }} {{ "건" | translate }}</td>
            </tr>
            <tr>
              <th>{{ "6월" | translate }}</th>
              <td>{{ context.June | number }} {{ "건" | translate }}</td>
            </tr>
            <tr>
              <th>{{ "7월" | translate }}</th>
              <td>{{ context.July | number }} {{ "건" | translate }}</td>
            </tr>
            <tr>
              <th>{{ "8월" | translate }}</th>
              <td>{{ context.August | number }} {{ "건" | translate }}</td>
            </tr>
            <tr>
              <th>{{ "9월" | translate }}</th>
              <td>{{ context.September | number }} {{ "건" | translate }}</td>
            </tr>
            <tr>
              <th>{{ "10월" | translate }}</th>
              <td>{{ context.October | number }} {{ "건" | translate }}</td>
            </tr>
            <tr>
              <th>{{ "11월" | translate }}</th>
              <td>{{ context.November | number }} {{ "건" | translate }}</td>
            </tr>
            <tr>
              <th>{{ "12월" | translate }}</th>
              <td>{{ context.December | number }} {{ "건" | translate }}</td>
            </tr>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- 월별 종료 -->

      <!--분기별 시작-->
      <v-expansion-panel>
        <v-expansion-panel-header class="font-weight-bold">
          {{ "분기별" | translate }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <table class="quarter_table_wrap mt-4">
            <tr>
              <th>1{{ "분기" |translate }}</th>
              <td>{{ firstQuarter }} {{ "건" | translate }}</td>
            </tr>
            <tr>
              <th>2{{ "분기" | translate }}</th>
              <td>{{ secondQuarter }} {{ "건" | translate }}</td>
            </tr>
            <tr>
              <th>3{{ "분기" | translate }}</th>
              <td>{{ thirdQuarter }} {{ "건" | translate }}</td>
            </tr>
            <tr>
              <th>4{{ "분기" | translate }}</th>
              <td>{{ forthQuarter }} {{ "건" | translate }}</td>
            </tr>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!--분기별 종료-->
    </v-expansion-panels>

    <v-card
      elevation="2"
    >
      <div class="font-weight-bold text-left pt-4 pb-3 px-2">
        {{ "반기별" |translate }}
      </div>
      <table class="px-4 pb-4 half_table">
        <tr>
          <th class="mb-2">
            {{ "상반기" | translate }}
          </th>
          <td>{{ firstHalf | number }} {{ "건" | translate }}</td>
        </tr>
        <tr>
          <th>{{ "하반기" | translate }}</th>
          <td>{{ secondHalf | number }} {{ "건" | translate }}</td>
        </tr>
      </table>
    </v-card>

    <v-card
      elevation="2"
      class="mt-6"
    >
      <div class="font-weight-bold text-left pt-4 pb-3 px-2">
        {{ "년" |translate }}
      </div>
      <table class="px-4 pb-4 year_table">
        <tr>
          <th>1 {{ "년" | translate }}</th>
          <td>{{ totalYear | number }} {{ "건" | translate }}</td>
        </tr>
      </table>
    </v-card>
  </div>
</template>

<script>
    import JDatePicker from "@/assets/plugins/mps-vuetify-compat/pickers/JDatePicker";
    import {PATH} from "@/constants";

    /** PC 버전의 기간별 A/S 요약에 해당 */
    export default {
        name: "ReportYearlyWork",
        components: {JDatePicker},
        data() {
            return {
              context: {},
              searchOptions: {
                searchYear: this.$now().startOf("year").toISO(),
              },
            }
        },
        computed: {
          searchOptionsByType() {
            const searchOptions = {
              startDate: null,
              endDate: null,
            };

            const d2 = this.$parseDate(this.searchOptions.searchYear);
            if (d2) {
              searchOptions.startDate = d2.startOf("year").toISO();
              searchOptions.endDate = d2.endOf("year").toISO();
            }

            // 검색 날짜가 없다면 잘못된 것이므로 일단 당해 년도로 처리
            if (!searchOptions.startDate) searchOptions.startDate = now().startOf("year").toISO();
            if (!searchOptions.endDate) searchOptions.endDate = now().endOf("year").toISO();

            return searchOptions;
          },

          /**
           * 1분기
           */
          firstQuarter() {
            return this.context.March +
                    this.context.February +
                    this.context.January;
          },

          /**
           * 2분기
           */
          secondQuarter() {
            return this.context.June +
                    this.context.May +
                    this.context.April;
          },

          /**
           * 3분기
           */
          thirdQuarter() {
            return this.context.September +
                    this.context.August +
                    this.context.July;
          },

          /**
           * 4분기
           */
          forthQuarter() {
            return this.context.December +
                    this.context.November +
                    this.context.October;
          },

          /**
           * 상반기
           */
          firstHalf() {
            return this.firstQuarter + this.secondQuarter;
          },

          /**
           * 하반기
           */
          secondHalf() {
            return this.thirdQuarter + this.forthQuarter;
          },

          /**
           * 해당 년도 합계
           */
          totalYear() {
            return this.firstHalf + this.secondHalf;
          },
        },
        watch: {
          "searchOptions.searchYear"() {
            this.load();
          },
        },
        mounted() {
          this.load();
        },
        methods: {
          async load() {
            this.loading = true;
            const res = await this.$request(PATH.REPORT.WORK.BY_MONTHLY)
                    .setObject(this.searchOptionsByType)
                    .enqueue();
            this.context = res;
            this.loading = false;
          },
        },
    }
</script>

<style scoped>
    .v-expansion-panel--active > .v-expansion-panel-header {
        min-height: 48px;
    }

    .v-expansion-panel-header {
        font-size: 1rem;
    }

    /*월별 시작*/
    .monthly_table_wrap {
        width: 100%;
        font-size: 0.9375rem;
    }

    .monthly_table_wrap th {
        text-align: left;
        height: 32px;
        font-weight: normal;
    }

    .monthly_table_wrap td {
        text-align: right;
    }

    /*월별 끝*/

    /*분기별 시작*/
    .quarter_table_wrap {
        width: 100%;
        font-size: 0.9375rem;
    }

    .quarter_table_wrap th {
        text-align: left;
        height: 32px;
        font-weight: normal;
    }

    .quarter_table_wrap td {
        text-align: right;
    }

    /*분기별 끝*/

    /*반기별 시작*/
    /*.half_table_wrap {
        width: 100%;
        font-size: 0.9375rem;
    }

    .half_table_wrap th {
        text-align: left;
        height: 32px;
        font-weight: normal;
    }

    .half_table_wrap td {
        text-align: right;
    }*/

    .half_table {
      font-size: 0.9375rem;
      display: flex;
      flex-direction: column;
    }

    .half_table tr {
      display: flex;
      justify-content: space-between;
    }

    .half_table tr th {
      font-weight: normal;
    }

    .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
      border-radius: 4px !important;
    }

    /*반기별 끝*/

    /*년 시작*/
    /*.year_table_wrap {
        width: 100%;
        font-size: 0.9375rem;
    }

    .year_table_wrap th {
        text-align: left;
        height: 32px;
        font-weight: normal;
    }

    .year_table_wrap td {
        text-align: right;
    }
*/

    .year_table {
      font-size: 0.9375rem;
      display: flex;
      flex-direction: column;
    }

    .year_table tr {
      display: flex;
      justify-content: space-between;
    }

    .year_table tr th {
      font-weight: normal;
    }

    /*년 끝*/
</style>
