<script>
    export default {
        name: "ChartVerticalBarPlugin",
        data() {
            return {}
        },
        methods: {
            itemCategoryName(chart) {
                /**
                 * css 관련 데이터가 있으면 가져와서 넣어줌
                 */
                let fs = null;
                let fc = null;
                let ml = null;

                if (this.barLabelCssObject) {
                    const {itemFontSize, itemFontColor, itmeFontMarginLeft} = this.barLabelCssObject

                    fs = itemFontSize
                    fc = itemFontColor
                    ml = itmeFontMarginLeft
                }

                if (chart.config.options.showValue) {
                    const ctx = chart.chart.ctx;
                    const fontSize = fs || "9";
                    const fontStyle = chart.config.options.showValue.fontStyle || "Arial";
                    ctx.font = fontSize + "px " + fontStyle;
                    ctx.textAlign = chart.config.options.showValue.textAlign || "center";
                    ctx.textBaseline = chart.config.options.showValue.textAlign || "bottom";
                    chart.config.data.datasets.map((dataset, i) => {
                        ctx.fillStyle = fc || "#000";
                        dataset.data.map((data, j) => {
                            if (dataset.hideValue !== true) {
                                const txt = Math.round(data * 100) / 100;
                                const xCoordinate = dataset._meta[chart.id].data[j]._model.x;
                                const yCoordinate = dataset._meta[chart.id].data[j]._model.y;
                                let yCoordinateResult;
                                if (dataset.type === 'line') {
                                    yCoordinateResult = yCoordinate + 21 > chart.scales[chart.options.scales.xAxes[0].id].top ? chart.scales[chart.options.scales.xAxes[0].id].top : yCoordinate + 21;
                                } else {
                                    yCoordinateResult = yCoordinate - 10;
                                }
                                ctx.fillText(txt, xCoordinate, yCoordinateResult);
                            }
                        });
                    });
                }
            },
            legendMarginBottom(chart) {
                chart.legend.afterFit = function() {
                    this.height = this.height + 20; // margin bottom 값
                };
            },
            yAxisUnit(chart) {
                const ctx = chart.chart.ctx;
                const fontSize = "9";
                const fontStyle = chart.config.options.showValue.fontStyle || "Arial";
                ctx.font = fontSize + "px " + fontStyle;
                ctx.textAlign = chart.config.options.showValue.textAlign || "center";
                ctx.textBaseline = chart.config.options.showValue.textAlign || "bottom";

                ctx.fillText('(천원)', chart.scales.A.right - 15,  chart.scales.A.top - 15);

            }
        }
    }
</script>

<style scoped>

</style>