
<script>
    export default {
        name: "ChartDoughnutPlugin",
        data(){
            return {}
        },
        methods:{
            textInDashboardDoughnut(chart){
                /**
                 * css 관련 데이터가 있으면 가져와서 넣어줌
                 */
                let flfs = null;
                let flfc = null;
                let flfw = null;
                let slfs = null;
                let slfc = null;
                let slfw = null;

                if(this.doughnutLabelCssObject){
                  const { firstLineInDoughnutFontSize, firstLineInDoughnutFontColor, firstLineInDoughnutFontWeight,
                        secondLineInDoughnutFontSize, secondLineInDoughnutFontColor, secondLineInDoughnutFontWeight
                  } = this.doughnutLabelCssObject

                    flfs = firstLineInDoughnutFontSize
                    flfc = firstLineInDoughnutFontColor
                    flfw = firstLineInDoughnutFontWeight
                    slfs = secondLineInDoughnutFontSize
                    slfc = secondLineInDoughnutFontColor
                    slfw = secondLineInDoughnutFontWeight
                }

                const ctx = chart.chart.ctx;
                const data = chart.config.data;

                if(data?.isInner){
                    const fisrtText = data.innerData.innerNum || '-';
                    const fisrtFontSize = flfs || 4;
                    const fisrtFontWeight = flfw || 'normal';
                    const fisrtTextX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                    const fisrtTextY = ((chart.chartArea.top + chart.chartArea.bottom) / 2) - 15; // 간격조정

                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    ctx.font = `${fisrtFontWeight} ${fisrtFontSize}em sans-serif`
                    ctx.fillStyle = flfc || 'black';
                    ctx.fillText(fisrtText, fisrtTextX, fisrtTextY);


                    const secondText = data.innerData.innerText || 'default';
                    const secondFontSize = slfs || 2;
                    const secondFontWeight = slfw || 'normal';
                    const secondTextX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                    const secondTextY = ((chart.chartArea.top + chart.chartArea.bottom) / 2) + 15; // 간격조정
                    ctx.font = `${secondFontWeight} ${secondFontSize}em sans-serif`
                    ctx.fillStyle = slfc || 'gray';
                    ctx.textBaseline = "middle";
                    ctx.fillText(secondText, secondTextX, secondTextY);
                }




            },

            itemCategoryName(chart){
                /**
                 * css 관련 데이터가 있으면 가져와서 넣어줌
                 */
                let fs = null;
                let fc = null;
                let fw = null;

                if(this.doughnutLabelCssObject){
                    const { itemFontSize, itemFontColor, itemFontWeight } = this.doughnutLabelCssObject

                    fs = itemFontSize
                    fc = itemFontColor
                    fw = itemFontWeight
                }

                const ctx = chart.chart.ctx;
                const fontSize = fs || 2;
                const fontWeight = fw || 'normal';
                ctx.font = `${fontWeight} ${fontSize}em sans-serif`
                ctx.textAlign = "start";
                ctx.textBaseline = "bottom";
                ctx.fillStyle = fc || "#000";


                chart.config.data.datasets.map((dataset) => {
                    dataset.data.map((data, j) => {
                        if (dataset.hideValue !== true && j !== dataset.data.length - 1 ) {
                            const model = dataset._meta[chart.id].data[j]._model
                            const radius = model.outerRadius * 0.9  // 반지름 줄이는 곳
                            const angle = model.startAngle
                            const X = model.x + ( radius * Math.cos(angle))
                            const Y = model.x + ( radius * Math.sin(angle))
                            const txt = model.label
                            const xCoordinate = Math.ceil(X);
                            const yCoordinate = Math.ceil(Y);
                            ctx.fillText(txt, xCoordinate, yCoordinate);
                        }
                    });
                });
            }
        }
    }
</script>

<style scoped>

</style>