<template>
  <div class="scroll--container">
    <div
      v-for="(item, i) in items"
      :key="i"
      class="scroll--card"
    >
      <dashboard-summary-status-card
        :item="item"
        :disabled="item.disabled"
      />
    </div>
  </div>
</template>

<script>
    import {PATH} from "@/constants";
    import DashboardSummaryStatusCard from "@/components/widgets/as/sub/DashboardSummaryStatusCard";
    import userManager from "@/assets/plugins/mps-common/user/user-manager";
    import Permissions from "@/constants/permissions";

    export default {
        name: "DashboardSummaryStatusWidget",
        components: {DashboardSummaryStatusCard},
        data() {
            return {
                statistics: {
                  outstanding: {},
                  expiration: {},
                  unvisited: {},
                },
            }
        },

        computed: {
          salesPermission() {
            return userManager.hasPermission(Permissions.EXPOSE_BILLING_MANAGE)
          },
            items() {
                return [
                  {
                    title: this.$translate("미수 거래처"),
                    firstLine: this.$number(this.statistics.outstanding.countOfoutstandingAccount),
                    secondLine: this.salesPermission ? this.$number(this.statistics.outstanding.sumOfRentalcompanyBalance) + this.$translate("원"):this.$translate("매출 권한 없음"),
                    disabled: this.statistics.outstanding.countOfoutstandingAccount === 0,
                    componentName: "OutstandingAccountSubList",
                    color: "#EC4DA3",
                  },
                  {
                    title: this.$translate("만기 도래 거래처"),
                    singleLine: this.$number(this.statistics.expiration.countOfExpiringPrinterContract),
                    disabled: this.statistics.expiration.countOfExpiringPrinterContract === 0,
                    componentName: "ExpirationAccountSubList",
                    color: "#0C98FE",
                  },
                  {
                    title: this.$translate("장기 미방문 거래처"),
                    singleLine: this.$number(this.statistics.unvisited.countOfUnvisitedAccounts),
                    disabled: this.statistics.unvisited.countOfUnvisitedAccounts === 0,
                    componentName: "UnvisitedAccountSubList",
                    color: "#AF4DEC",
                  },
                ]
            },
        },

        created() {
            // this.loadStatistics();
        },

        mounted() {
        },

        methods: {
            async loadStatistics() {
                try {
                    const response = await this.$request(PATH.DASHBOARD.HOME_DASHBOARD_STATISTICS_MOBILE)
                        .setObject({})
                        .enqueue();
                    this.statistics = response;
                } catch (error) {
                }
            },

            refresh() {
                this.loadStatistics();
            },
        }
    }
</script>


<style scoped>
    .v-application ul, .v-application ol {
        padding-left: 0 !important;
    }

    ul {
        height: 100px;
        padding: 10px 0;
        width: 33.3%;
    }

    ul > li {
        text-align: center;
    }

    ul:nth-child(1) {
        background: #0c98fe;
    }

    ul:nth-child(2) {
        background: #ec4da3;
        margin: 0 10px;
    }

    ul:nth-child(3) {
        background: #af4dec;
    }

/*    .scroll--container {
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        height: 120px;
    }*/

    .scroll--container {
      display: flex;
      justify-content: space-between;
    }

    .scroll--card {
        display: inline-block;
        color: white;
        width: 33.3%;
    }

    .scroll--card:not(:first-child) {
        margin-left: 10px;
    }

    >>>.v-list-item__title, .v-list-item__subtitle {
      /* overflow: hidden; */
      /* text-overflow: ellipsis; */
      white-space: pre-wrap !important;
      word-break: keep-all;
      line-height: 1.3 !important;
      align-self: start !important;
      text-overflow: inherit;
    }


    >>>.v-list--three-line .v-list-item .v-list-item__content {
      -ms-flex-item-align: stretch;
      /* align-self: stretch; */
      display: flex;
      flex-direction: column;
      /* height: 100%; */
    }

    >>>.v-list--three-line .v-list-item, .v-list-item--three-line {
      /* min-height: 100%; */
      background: red;
      height: 100%;
    }

/*
    @media (min-width: 768px) {
      >>>.v-list-item__content {
        padding: 15px 0 10px 0 !important;
        height: 100% !important;
      }

      >>>.v-list-item__title, .v-list-item__subtitle {
        font-size: 1.2rem !important;
        max-width: 120px;
        margin: 0 auto;
      }

      >>>.white--text {
        font-size: 1rem !important;
      }

      >>>.caption {
        font-size: 1rem !important;
      }
    }
 */

    @media (max-width:320px) {
/*      >>>.white--text {
        font-size: 0.8rem !important;
      }*/

      >>>.caption {
        font-size: 0.5rem !important;
      }
    }
</style>