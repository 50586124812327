<template>
  <div
    class="main-as-widget-root"
    style="margin: 25px 0;"
  >
    <v-row
      align="start"
      no-gutters
    >
      <v-layout class="layout1">
        <v-card
          v-for="(e,index) in counts"
          :key="index"
          tile
          width="33.33%"
          class="align-center pb-5"
          outlined
          @click="onClicked(e)"
        >
          <as-counter
            :count="e.count"
            :icon="e.icon"
            :v-icon="e.vIcon"
            :title="e.title"
            class="py-5"
          />
        </v-card>
      </v-layout>
    </v-row>
    <v-row
      align="start"
      no-gutters
    >
      <v-layout class="layout2">
        <v-card
          v-for="(e,index) in counts2"
          :key="index"
          tile
          width="33.33%"
          class="align-center pb-5"
          outlined
          @click="onClicked(e)"
        >
          <as-counter
            :count="e.count"
            :icon="e.icon"
            :v-icon="e.vIcon"
            :title="e.title"
            class="py-5"
          />
        </v-card>
      </v-layout>
    </v-row>
  </div>
</template>

<script>
    import AsCounter from "@/components/widgets/AsCounter";
    import {PATH} from "@/constants";
    import AsMainWorkorderForAdmin from "@/components/windows/as/AsMainWorkorderSubList";

    export default {
        name: "MainAsWidget",
        components: {AsCounter},
        data() {
            return {
                raw: undefined,

            }
        },
        computed: {
            counts() {
                if (this.raw === undefined) return;
                const {
                    asReadyCnt,
                    asProcCnt,
                    asReservedCnt,
                } = this.raw;
                return [
                    {
                        title: this.$translate("AS_접수"),
                        icon: require('@/assets/images/main/ic_main_count_receipt.png'),
                        count: asReadyCnt,
                        name: "AsMainReceivedSubList",
                        disabled: !asReadyCnt,
                    },
                    {
                        title: this.$translate("AS 진행상태"),
                        icon: require('@/assets/images/main/ic_main_count_progress.png'),
                        count: asProcCnt,
                        name: "AsMainInProgressSubList",
                        disabled: !asProcCnt,
                    },
                    {
                        title: this.$translate("AS 일정"),
                        icon: require('@/assets/images/main/ic_main_count_progress.png'),
                        count: asReservedCnt,
                        name: "AsMainReservedSubList",
                        disabled: !asReservedCnt,
                    },
                ]
            },
            counts2() {
                if (this.raw === undefined) return;
                const {
                    asWaitCnt,
                    asDayFinishCnt,
                    asDayCancelCnt,
                } = this.raw;
                return [
                    {
                        title: this.$translate("AS 기사대기"),
                        icon: require('@/assets/images/main/ic_main_count_new.png'),
                        count: asWaitCnt,
                        name: "AsMainWatingStaffSubList",
                        disabled: !asWaitCnt,
                    },
                    {
                        title: this.$translate("당일 AS취소"),
                        icon: require('@/assets/images/main/ic_main_count_termination.png'),
                        count: asDayCancelCnt,
                        name: "AsMainDayCancelSubList",
                        disabled: !asDayCancelCnt,
                    },
                    {
                        title: this.$translate("당일 AS완료"),
                        icon: require('@/assets/images/main/ic_main_count_complete.png'),
                        count: asDayFinishCnt,
                        name: "AsMainDayFinishSubList",
                        disabled: !asDayFinishCnt,
                    }
                ]
            },
        },
        created() {
        },
        mounted() {
        },
        methods: {
            loadWorkorderSummary() {
                this.$request(PATH.WORK_ORDER.SUMMARY)
                    .post()
                    .enqueue()
                    .then(res => this.raw = res.data || {});
            },
            onClicked(e) {
                if (!e.name) return;
                if (e.disabled) return;
                this.routerManager.push({name: e.name});
            },

            refresh() {
                this.loadWorkorderSummary();
            },
        }
    }
</script>

<style scoped>
    .layout > div:nth-child(1),
    .layout > div:nth-child(2) {
        border-right: none !important;
    }

    .main-as-widget-root > .row:nth-child(2) > .layout > div {
        border-top: 0px;
    }

    >>>.theme--light.v-card.v-card--outlined {
       border: 1px solid #ddd;
     }

/*
    >>>.layout1 > div:first-child {
      border-top-left-radius: 4px;
    }

    >>>.layout1 > div:last-child {
      border-top-right-radius: 4px;
    }

    >>>.layout2 > div:first-child {
      border-bottom-left-radius: 4px;
    }

    >>>.layout2 > div:last-child {
      border-bottom-right-radius: 4px;
    }
*/

</style>