<template>
  <span>
    <span>
      <a
        :href="hasPhone ? 'sms:' + telNo : 'javascript: void(0)'"
        @click.stop
      >
        <img src="@/assets/images/main/svg/sms_outlined.svg">
      </a>
    </span>

    <span style="margin-left: 20px;">
      <a
        :href="hasPhone ? 'tel:' + telNo : 'javascript: void(0)'"
        @click.stop
      >
        <img src="@/assets/images/main/svg/call_outlined.svg">
      </a>
    </span>
  </span>
</template>

<script>
    export default {
        name: "ContextIconMenu",
        props: {
            telNo: {
                type: String,
                default: undefined
            },
        },
        computed: {
            hasPhone() {
                return this.$isNotEmpty(this.telNo);
            },
        }
    }
</script>

<style scoped>

</style>