<template>
  <v-layout>
    <v-card
      class="text-left mt-2"
      flat
      style="width: 100%;"
      tile
    >
      <div class="d-flex justify-space-between align-center headline">
        <span>{{ currentMonth }}{{ '월 매출현황' | translate }}</span>
        <span class="font-weight-bold">{{ salesSummary.priceCurrentMonthSummary | number }}{{ "원" | translate }}</span>
      </div>
      <!--        <span class="caption">7월 26일 기준</span>-->
      <span class="subtitle-2">({{ currentMonth }}{{ '월' | translate }} 1{{ '일' | translate }} ~ {{ currentMonth }}{{ '월' | translate }} {{ currentMonthEnd }}{{ '일' | translate }})</span>
    </v-card>
  </v-layout>
</template>

<script>
    import {PATH} from "@/constants";

    export default {
        name: "SalesStatus",
        components: {},
        data() {
            return {
              salesSummary: {},
            }
        },
        computed: {
            currentMonth() {
                return this.$parseDate(this.$now()).month;
            },
            currentMonthEnd() {
                return this.$parseDate(this.$now()).daysInMonth;
            },
        },
        created() {
          // this.loadSalesSummaryCurrentMonth();
        },
        methods: {
          async loadSalesSummaryCurrentMonth() {
            try {
              const response = await this.$request(PATH.DASHBOARD.HOME_DASHBOARD_SALES_SUMMARY_THIS_MONTH)
                      .setObject({})
                      .enqueue();
              this.salesSummary = response;
            } catch (error) {
            }
          },

          refresh() {
            this.loadSalesSummaryCurrentMonth();
          },
        }
    }
</script>

<style scoped>
    .v-application ul, .v-application ol {
        padding-left: 0 !important;
    }

    ul {
      height: 100px;
      padding: 10px 0;
      width: 33.3%;
    }

    ul > li {
        text-align: center;
    }

    ul:nth-child(1) {
     background: #ec4da3;
    }

    ul:nth-child(2) {
      background: #0c98fe;
      margin: 0 10px;
    }

    ul:nth-child(3) {
      background: #af4dec;
    }

/*    @media (max-width: 320px) {
      >>>.headline {
        font-size: 1.4rem !important;
      }

      >>>.subtitle-2 {
        font-size: 0.8rem !important;
      }
    }*/
</style>