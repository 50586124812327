import { render, staticRenderFns } from "./DashboardSummaryStatusWidget.vue?vue&type=template&id=5cfed20a&scoped=true"
import script from "./DashboardSummaryStatusWidget.vue?vue&type=script&lang=js"
export * from "./DashboardSummaryStatusWidget.vue?vue&type=script&lang=js"
import style0 from "./DashboardSummaryStatusWidget.vue?vue&type=style&index=0&id=5cfed20a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cfed20a",
  null
  
)

export default component.exports