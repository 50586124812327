<template>
  <v-dialog
    v-model="dialog"
    max-width="300px"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <div class="widget_modal_title_wrap">
        <div>{{ '위젯 설정' | translate }}</div>
        <v-btn
          :ripple="false"
          icon
          @click="onDialogClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="widget_modal_info">
        {{ '보고싶은 항목을 선택하세요' | translate }}
      </div>
      <div class="widget_select_view_wrap">
        <div
          class="widget_select_box"
          :class="{'active': isShow.sales.value}"
          @click="() => onChangeShow('sales')"
        >
          <img :src="isShow.sales.value ? require(`@/assets/images/dashboard/dashboard_sale_icon.svg`) : require(`@/assets/images/dashboard/dashboard_sale_mono_icon.svg`)">
          <div>{{ '매출' | translate }}</div>
        </div>
        <div
          class="widget_select_box"
          :class="{'active': isShow.account.value}"
          @click="() => onChangeShow('account')"
        >
          <img :src="isShow.account.value ? require(`@/assets/images/dashboard/dashboard_account_icon.svg`) : require(`@/assets/images/dashboard/dashboard_account_mono_icon.svg`)">
          <div>{{ '거래처' | translate }}</div>
        </div>
        <div
          class="widget_select_box"
          :class="{'active': isShow.contract.value}"
          @click="() => onChangeShow('contract')"
        >
          <img :src="isShow.contract.value ? require(`@/assets/images/dashboard/dashboard_contract_icon.svg`) : require(`@/assets/images/dashboard/dashboard_contract_mono_icon.svg`)">
          <div>{{ '계약 현황' | translate }}</div>
        </div>
        <div
          class="widget_select_box"
          :class="{'active': isShow.as.value}"
          @click="() => onChangeShow('as')"
        >
          <img :src="isShow.as.value ? require(`@/assets/images/dashboard/dashboard_as_icon.svg`) : require(`@/assets/images/dashboard/dashboard_as_mono_icon.svg`)">
          <div>{{ 'A/S' | translate }}</div>
        </div>
        <div
          class="widget_select_box"
          :class="{'active': isShow.auto.value}"
          @click="() => onChangeShow('auto')"
        >
          <img :src="isShow.auto.value ? require(`@/assets/images/dashboard/dashboard_automation_icon.svg`) : require(`@/assets/images/dashboard/dashboard_automation_mono_icon.svg`)">
          <div>{{ '자동화' | translate }}</div>
        </div>
      </div>
      <div
        class="complete_button"
        @click="onClickHandler"
      >
        <button>{{ '설정 완료' | translate }}</button>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>


    export default {
        name: "RenewalDashboardEntireFilterModal",
        props: {
            dialog: Boolean,
            showWidget: Object
        },
        data() {
            return {
                isShow: {
                    sales: {
                        key: 'sales',
                        value: true,
                    },
                    account: {
                        key: 'account',
                        value: true,
                    },
                    contract: {
                        key: 'contract',
                        value: true,
                    },
                    as: {
                        key: 'as',
                        value: true,
                    },
                    auto: {
                        key: 'auto',
                        value: true,
                    },
                },
                isChange: {
                    sales: false,
                    account: false,
                    contract: false,
                    as: false,
                    auto: false
                }
            }
        },
        watch: {
            dialog(b, v) {
                if (v === false) {
                    this.isShow.sales.value = this.showWidget.sales.isShow
                    this.isShow.account.value = this.showWidget.account.isShow
                    this.isShow.contract.value = this.showWidget.contract.isShow
                    this.isShow.as.value = this.showWidget.as.isShow
                    this.isShow.auto.value = this.showWidget.auto.isShow
                }
            }
        },
        methods: {
            onDialogClose() {
                this.$emit('onDialogClose')
            },

            onChangeShow(widget) {
                this.isShow[widget].value = !this.isShow[widget].value;
                this.isChange[widget] = true;
            },

            onClickHandler() {
                if (Object.values(this.isChange).includes(true)) {
                    const data = {isShow: this.isShow, isChange: this.isChange}
                    this.$emit('onHandleChange', 'onChangeShowWidget', data)
                }

                this.onDialogClose();

            }
        }
    }
</script>

<style scoped>
    * {
        font-family: "Noto Sans KR", sans-serif;
    }

    .v-sheet.v-card {
        padding: 23px 35px;
        text-align: left;
    }

    .widget_modal_title_wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .widget_modal_title_wrap div {
        font-size: 1rem;
        color: #333;
        font-weight: 500;
    }

    .widget_modal_info {
        font-weight: normal;
        font-size: 0.75rem;
        color: #8e8e8e;
        margin-bottom: 14px;
    }

    .widget_select_view_wrap {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 18px;
    }

    .widget_select_view_wrap > div {
        width: 68px;
        height: 66px;
    }

    /* 위젯이 선택했을때 적용될 css 입니다.
    선택 시 클래스를 추가해주세요 */
    .active {
        border: 1px solid #0C98FE !important;
        color: #0C98FE !important;
        background-color: rgba(12, 152, 254, 0.07);
        background-image: url(/img/check.svg);
        background-repeat: no-repeat;
        background-position: 93% 8%;
    }

    .widget_select_box {
        color: #878787;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid #ddd;
        font-size: 0.875rem;
        margin: 4px;
    }


    .complete_button {
        width: 100%;
        text-align: center;
    }

    .complete_button button {
        width: 100%;
        height: 34px;
        color: white;
        background-color: #0c98fe;
        border-radius: 2px;
        font-size: 0.833rem;
    }

</style>